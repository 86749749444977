import { FC } from "react";
import { NavLink } from "react-router-dom";

const RoadmapButton: FC = () => {
	return (
		<div className="flex justify-center">
		<NavLink
		to={"https://www.manifesto.applia-europe.eu/?utm_source=statreport"}
		type="fluid"
		className="text-white lg:self-start bg-secondary hover:bg-secondary-hover focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm text-center px-10 py-2.5 focus:outline-none"
		>Click here to read our manifesto</NavLink>
		</div>
	);
};

export { RoadmapButton };