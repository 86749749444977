import { FC } from "react";
import classNames from "classnames";

import { LegendProps } from "./Legend.type";

const Legend: FC<LegendProps> = ({ border = true, direction = "horizontal", items }) => {
	return (
		<ul className={classNames("flex p-3 gap-4 flex-wrap", { "flex-col p-5": direction === "vertical", border: border })}>
			{items.map((item) => (
				<li key={item.label + item.color} className="flex gap-2 items-center">
					<span style={{ backgroundColor: item.color }} className="w-4 h-4" />
					<span className="text-base">{item.label}</span>
				</li>
			))}
		</ul>
	);
};

export { Legend };
