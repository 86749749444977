import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Legend } from "../../../Charts/Legend/Legend";
import { LegendProps } from "../../../Charts/Legend/Legend.type";
import { LineChart } from "../../../Charts/LineChart/LineChart";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { PatentsFiledAndGrantedForHomeAppliancesProps } from "./PatentsFiledAndGrantedForHomeAppliances.type";
import { PATENTS_FILED_AND_GRANTED_FOR_HOME_APPLIANCES_CHART_DATA_KEYS } from "./PatentsFiledAndGrantedForHomeAppliances.const";

const PatentsFiledAndGrantedForHomeAppliances: FC<PatentsFiledAndGrantedForHomeAppliancesProps> = ({ data }) => {
	const { t } = useTranslation();
	const chartSize = useBreakpoint();

	const [legendDirection, setLegendDirection] = useState<LegendProps["direction"]>("horizontal");

	const [chartData, setChartData] = useState(data);
	const [axisFontSize, setAxisFontSize] = useState(12);
	const [marginLeft, setMarginLeft] = useState(0);
	const [xAxisPadding, setXAxisPadding] = useState(50);
	const [chartHeight, setChartHeight] = useState(450);

	const legendData = useMemo(() => {
		return PATENTS_FILED_AND_GRANTED_FOR_HOME_APPLIANCES_CHART_DATA_KEYS.map((dataKey) => {
			const label = typeof dataKey?.translation === "string" ? t(dataKey?.translation) : dataKey.name;

			return {
				color: dataKey.color,
				label: label,
			};
		});
	}, [t]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setChartData(data.filter((dataEntry) => ["2017", "2018", "2019", "2020", "2021", "2022"].includes(dataEntry.name)));
				setMarginLeft(-15);
				setAxisFontSize(9);
				setXAxisPadding(20);
				setChartHeight(250);
				setLegendDirection("vertical");
				break;

			case "sm":
				setChartData(data.filter((dataEntry) => ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"].includes(dataEntry.name)));
				setMarginLeft(-5);
				setAxisFontSize(11);
				setXAxisPadding(15);
				setChartHeight(350);
				setLegendDirection("vertical");
				break;

			case "md":
				setMarginLeft(-5);
				setChartData(data);
				setAxisFontSize(12);
				setXAxisPadding(20);
				setChartHeight(350);
				setLegendDirection("horizontal");
				break;

			case "lg":
			case "xl":
			default:
				setMarginLeft(0);
				setChartData(data);
				setAxisFontSize(14);
				setXAxisPadding(35);
				setChartHeight(450);
				setLegendDirection("horizontal");
				break;
		}
	}, [chartSize, data]);

	const formatNumber = useCallback((number: number) => {
		return new Intl.NumberFormat("nl-BE").format(number);
	}, []);

	return (
		<div className="flex flex-col gap-8">
			<div className="self-stretch md:self-center">
				<Legend items={legendData} direction={legendDirection} />
			</div>

			<LineChart
				data={chartData}
				height={chartHeight}
				marginLeft={marginLeft}
				labelProps={{ fontSize: axisFontSize, formatter: formatNumber }}
				XAxisProps={{
					dataKey: "name",
					padding: { left: xAxisPadding, right: xAxisPadding },
					tick: { fontSize: axisFontSize },
				}}
				YAxisProps={{
					tickCount: 9,
					tick: { fontSize: axisFontSize },
					tickFormatter: formatNumber,
					domain: [0, 16000],
					interval: 0,
				}}
				dataKeys={PATENTS_FILED_AND_GRANTED_FOR_HOME_APPLIANCES_CHART_DATA_KEYS}
				showToolTip={false}
			/>
		</div>
	);
};

export { PatentsFiledAndGrantedForHomeAppliances };
