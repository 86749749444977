import { FC } from "react";
import classNames from "classnames";

import { Card } from "../../../../Card/Card";
import { useBoolean } from "../../../../../hooks/useBoolean";

import { StaffMemberProps } from "./StaffMember.type";

const StaffMember: FC<StaffMemberProps> = ({ name, background, functionTitle, imageUrl, toggleText }) => {
	const [isOpen, { toggle }] = useBoolean(false);

	return (
		<div className="grow">
			<Card fluid>
				<div className="w-full relative overflow-hidden aspect-square">
					<div className="animate-pulse bg-slate-200 w-full h-full absolute left-0 top-0 z-[1]"></div>
					<img className="w-full h-full absolute left-0 top-0 z-[2]" role="presentation" src={imageUrl} alt="" title={name} />
				</div>
				<div className="pb-5">
					<div className="px-5 pt-4 flex flex-col gap-1">
						<p className="text-xl">{name}</p>
						<p className="font-light text-gray-500/75">{functionTitle}</p>

						<button className="text-blue-900 hover:text-blue-700 self-start mt-2" onClick={toggle}>
							{toggleText} {isOpen ? "-" : "+"}
						</button>
					</div>

					<div
						className={classNames("transition-max-height h-auto overflow-hidden duration-700 ease-in-out", {
							"max-h-0": !isOpen,
							"max-h-[2999px]": isOpen,
						})}
					>
						<p className="px-5 py-0 pt-4" dangerouslySetInnerHTML={{ __html: background }} />
					</div>
				</div>
			</Card>
		</div>
	);
};

export { StaffMember };
