import { FC, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useRouteInfo } from "../../hooks/route/useRouteInfo";

const ContentFooter: FC = () => {
	const { t } = useTranslation();

	const { next, previous } = useRouteInfo();

	const scrollToTop = useCallback(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 py-4 md:py-12">
			{previous?.url ? (
				<NavLink
					key={previous?.url}
					className="text-blue-800 flex gap-x-4 md:gap-x-6 items-center w-full md:w-80 px-4 md:px-5 py-6 border-2 border-transparent text-base leading-base text-link rounded-lg group focus:text-link focus:bg-primary-hover/5 focus:border-link focus:border-opacity-100 focus:border-2 focus:ring-1 focus:ring-offset-4 focus:ring-blue-40 active:ring-0 active:ring-offset-0 hover:bg-gray-50 flex-row justify-self-start text-left"
					to={previous?.url}
					onClick={scrollToTop}
				>
					<svg
						stroke="currentColor"
						fill="currentColor"
						strokeWidth="0"
						viewBox="0 0 20 20"
						aria-hidden="true"
						className="h-6 w-6 shrink-0 inline rotate-90"
						height="1em"
						width="1em"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
							clipRule="evenodd"
						></path>
					</svg>
					<span className="flex flex-col gap-1 justify-end items-start">
						<span className="uppercase font-semibold tracking-wide text-blue-800 text-sm">Previous</span>
						{previous?.pillarNumber && (
							<span className="text-gray-600 uppercase text-sm tracking-wide">{`${t("pages.pillar.pillar")} ${
								previous.pillarNumber
							}`}</span>
						)}
						{previous?.pathName && <span className="text-gray-600 uppercase text-sm tracking-wide">{t(previous.pathName)}</span>}
						<span className="text-gray-600 group-hover:underline">{t(previous?.label)}</span>
					</span>
				</NavLink>
			) : (
				<div></div>
			)}
			{next?.url ? (
				<NavLink
					key={next?.url}
					className="text-blue-800 flex gap-x-4 md:gap-x-6 items-center w-full md:w-80 px-4 md:px-5 py-6 border-2 border-transparent text-base leading-base text-link rounded-lg group focus:text-link focus:bg-primary-hover/5 focus:border-link focus:border-opacity-100 focus:border-2 focus:ring-1 focus:ring-offset-4 focus:ring-blue-40 active:ring-0 active:ring-offset-0 hover:bg-gray-50 flex-row-reverse justify-self-end text-right"
					to={next?.url}
					onClick={scrollToTop}
				>
					<svg
						stroke="currentColor"
						fill="currentColor"
						strokeWidth="0"
						viewBox="0 0 20 20"
						aria-hidden="true"
						className="h-6 w-6 shrink-0 inline -rotate-90"
						height="1em"
						width="1em"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
							clipRule="evenodd"
						></path>
					</svg>
					<span className="flex flex-col gap-1 justify-end items-end">
						<span className="uppercase font-semibold tracking-wide text-blue-800 text-sm">Next</span>
						{next?.pillarNumber && (
							<span className="text-gray-600 uppercase text-sm tracking-wide">{`${t("pages.pillar.pillar")} ${next.pillarNumber}`}</span>
						)}
						{next?.pathName && <span className="text-gray-700 uppercase text-sm tracking-wide">{t(next.pathName)}</span>}
						<span className="text-gray-600 group-hover:underline">{t(next?.label)}</span>
					</span>
				</NavLink>
			) : (
				<div></div>
			)}
		</div>
	);
};

export { ContentFooter };
