import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/vectors/content/introduction/the-home-appliance-industry-in-europe-in-2023/all-desktop.svg";
import Mobile from "../../../../assets/vectors/content/introduction/the-home-appliance-industry-in-europe-in-2023/all-mobile.svg";

const HomeApplianceIndustryInEuropeIn2023: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col">
			<img
				className="hidden md:block w-full max-w-3xl self-center"
				src={Desktop}
				alt={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
				title={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
			/>
			<img
				className="block md:hidden w-full max-w-sm self-center"
				src={Mobile}
				alt={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
				title={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
			/>
		</div>
	);
};

export { HomeApplianceIndustryInEuropeIn2023 };
