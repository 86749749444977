import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Container } from "../../components/Container/Container";
import { Seo } from "../../components/Seo/Seo";
import backgroundImage from "../../assets/images/eu-flag.webp";

export const Home = () => {
	const { t } = useTranslation();

	return (
		<>
			<Seo title={t("pages.home.seoTitle")} description={t("pages.home.seoDescription") as string} />

			<div className="flex flex-col justify-start min-h-[calc(100vh-5rem)]">
				<div className="flex flex-col grow shrink max-sm:grow-0 max-sm:max-h-none max-h-[37.5rem] md:max-h-none">
					<Container
						src={backgroundImage}
						containerClassName="grow md:flex md:flex-col md:justify-center"
						contentClassName="md:justify-center w-full grow"
					>
						<div className="w-full sm:w-3/4 xl:w-1/2 2xl:w-2/3 flex flex-col gap-8 md:gap-10 pt-16 lg:pt-0">
							<h1 className="text-white">{t("pages.home.title")}</h1>
							<p className="text-2xl text-white">{t("pages.home.subTitle")}</p>

							<div className="w-full flex flex-col gap-4 max-w-[65%] lg:max-w-none">
								<p className="text-white text-lg font-bold">{t("cta.start.title")}</p>
								<div className="flex flex-col lg:flex-row gap-4">
									<NavLink
										to={"/introduction/foreword"}
										className="text-white lg:self-start bg-primary hover:bg-primary-hover focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm text-center px-10 py-2.5 focus:outline-none"
									>
										{t("cta.start.startReading")}
									</NavLink>
									<a
										href={`${window.location.origin}/files/pdf/20240403-APPLiA-print-version-final.pdf`}
										className="lg:self-start focus:ring-4 focus:ring-blue-300 font-medium text-sm text-center rounded-xl px-10 py-2.5 text-white border border-white bg-transparent hover:bg-transparent hover:border-slate-300 hover:text-slate-300 focus:outline-none"
										download={`APPLiA - ${t("pages.home.title")}`}
									>
										{t("cta.start.downloadPdf")}
									</a>
								</div>
							</div>
						</div>
					</Container>
				</div>
				<div className="md:hidden flex flex-col gap-1 px-1 py-3">
					<NavLink className="text-lg px-5 py-3 bg-romance drop-shadow-sm" to="/introduction/foreword">
						{t("navigation.introduction.index")}
					</NavLink>
					<NavLink className="text-lg px-5 py-3 bg-romance drop-shadow-sm" to="/pillar/1">
						{`${t("pages.pillar.pillar")} 1: ${t("navigation.pillar1.index")}`}
					</NavLink>
					<NavLink className="text-lg px-5 py-3 bg-romance drop-shadow-sm" to="/pillar/2">
						{`${t("pages.pillar.pillar")} 2: ${t("navigation.pillar2.index")}`}
					</NavLink>
					<a className="text-lg px-5 py-3 bg-romance drop-shadow-sm" target="_blank" href="https://www.applia-europe.eu/" rel="noreferrer">
						{t("navigation.visitAppliaWebsite")}
					</a>
				</div>
			</div>
		</>
	);
};
