import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Select } from "../../../../Select/Select";

import { categories, units, types } from "./FilterDropdown.const";
import { FilterDropdownProps, FilterInput } from "./FilterDropdown.type";

const FilterDropdown: FC<FilterDropdownProps> = ({ onChange }) => {
	const { t } = useTranslation();

	const { register, watch } = useForm<FilterInput>({
		defaultValues: {
			type: types[0],
			category: categories[0],
			unit: units[0],
		},
	});

	useEffect(() => {
		if (!onChange) {
			return;
		}
		const subscription = watch((value) => {
			const { type, category, unit } = value;
			if (type && category && unit) {
				onChange({ type, category, unit });
			}
		});
		return () => subscription.unsubscribe();
	}, [onChange, watch]);

	return (
		<form className="flex md:flex-row flex-col gap-5">
			<div className="flex flex-col">
				<label className="font-bold mb-2" htmlFor="type-box">
					{t("general.filter.type")}
				</label>
				<Select id="type-box" options={types} {...register("type", { required: true })} />
			</div>
			<div className="flex flex-col">
				<label className="font-bold mb-2" htmlFor="category-box">
					{t("general.filter.category")}
				</label>
				<Select id="category-box" options={categories} {...register("category", { required: true })} />
			</div>
			<div className="flex flex-col">
				<label className="font-bold mb-2" htmlFor="unit-box">
					{t("general.filter.unit")}
				</label>
				<Select id="unit-box" options={units} {...register("unit", { required: true })} />
			</div>
		</form>
	);
};

export { FilterDropdown };
