import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Container } from "../../components/Container/Container";
import { ContentBuilder } from "../../components/ContentBuilder/ContentBuilder";
import { ContentFooter } from "../../components/ContentFooter/ContentFooter";
import { ContentStatus } from "../../components/ContentStatus/ContentStatus";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Seo } from "../../components/Seo/Seo";
import { usePageContent } from "../../hooks/content/usePageContent";
import { useRouteInfo } from "../../hooks/route/useRouteInfo";

const IntroductionDetail: FC = () => {
	const { routeData, title, description } = useRouteInfo();
	const { t } = useTranslation();

	const { blocks, heroImage, heroIntro, pageIntro } = usePageContent(routeData?.label);

	return (
		<>
			<Seo
				title={title}
				description={typeof description === "string" && description.trim().length > 0 ? (t(description) as string) : undefined}
			/>

			<article className="break-words">
				<PageHeader
					backgroundImage={heroImage}
					title={t(routeData?.label as string)}
					{...(!!heroIntro && {
						intro: t(heroIntro) as string,
					})}
					containerClassName={classNames({ "justify-center": !heroIntro })}
				/>

				<Container {...(!pageIntro && { contentClassName: "!py-0" })}>
					<div className="w-full mx-auto max-w-6xl">
						<ContentStatus status={routeData?.status} />
						{typeof pageIntro !== "undefined" ? <p className="text-xl leading-relaxed">{t(pageIntro)}</p> : null}
						<ContentBuilder blocks={blocks} />
						<ContentFooter />
					</div>
				</Container>
			</article>
		</>
	);
};

export { IntroductionDetail };
