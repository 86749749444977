import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { AppliaRoute } from "../../config/routes/routes";
import { Container } from "../../components/Container/Container";
import { ContentBuilder } from "../../components/ContentBuilder/ContentBuilder";
import { ContentFooter } from "../../components/ContentFooter/ContentFooter";
import { ContentStatus } from "../../components/ContentStatus/ContentStatus";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Seo } from "../../components/Seo/Seo";
import { usePageContent } from "../../hooks/content/usePageContent";
import { useRouteInfo } from "../../hooks/route/useRouteInfo";

const Pillar: FC = () => {
	const { routeData, title, description } = useRouteInfo();
	const { t } = useTranslation();

	const { blocks, heroImage, heroIntro, pageIntro } = usePageContent(routeData?.label);

	return (
		<>
			<Seo
				title={title}
				description={typeof description === "string" && description.trim().length > 0 ? (t(description) as string) : undefined}
			/>

			<article className="break-words">
				{/* Page header mobile */}
				<div className="block md:hidden">
					<PageHeader
						backgroundImage={heroImage}
						title={
							<>
								<p className="uppercase text-white text-sm">
									{t("pages.pillar.pillar")} {(routeData as AppliaRoute)?.pillarNumber}
								</p>
								<h1 className="text-4xl text-white font-semibold">{t(routeData?.label as string)}</h1>
							</>
						}
						{...(!!heroIntro && {
							intro: t(heroIntro) as string,
						})}
					/>
				</div>

				{/* Page header desktop */}
				<div className="hidden md:block">
					<PageHeader
						backgroundImage={heroImage}
						title={
							<h1 className={classNames("text-3xl xl:text-5xl", { "text-white": !!heroImage })}>
								{t("pages.pillar.pillar")} {(routeData as AppliaRoute)?.pillarNumber}
								<br />
								<span>{t(routeData?.label as string)}</span>
							</h1>
						}
						{...(!!heroIntro && {
							intro: t(heroIntro) as string,
						})}
					/>
				</div>
				<Container contentClassName="!py-0">
					<div className={classNames("max-w-6xl mx-auto", { "pt-14": typeof pageIntro !== "undefined" })}>
						<ContentStatus status={routeData?.status} />
						{typeof pageIntro !== "undefined" ? <p className="text-xl leading-relaxed">{t(pageIntro)}</p> : null}
						<ContentBuilder blocks={blocks} />
						<ContentFooter />
					</div>
				</Container>
			</article>
		</>
	);
};

export { Pillar };
