import { useCallback, useLayoutEffect } from "react";

export const useLockBodyScroll = (locked: boolean): void => {
	const lock = useCallback(() => {
		document.body.classList.add("overflow-hidden");
	}, []);

	const unlock = useCallback(() => {
		document.body.classList.remove("overflow-hidden");
	}, []);

	useLayoutEffect(() => {
		if (locked) {
			lock();
		} else {
			unlock();
		}
	}, [lock, locked, unlock]);
};
