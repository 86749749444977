import { Cell, LabelProps } from "recharts";

import { filterAxisProps } from "../../Charts/shared/CustomizedYAxisLabel/CustomizedYAxisLabel.helper";
import { getAttrsOfCartesianLabel } from "../../../utils/recharts/label/getLabelAttributes";
import { UiColors } from "../../../config/ui/colors";

import { MatrixDiagramData } from "./MatrixDiagram.type";

export const parseDomain = (data: MatrixDiagramData) => {
	const max = Math.max.apply(
		null,
		Object.keys(data).map((material) => {
			return Math.max.apply(
				null,
				data[material].map((entry) => entry.value),
			);
		}),
	);

	return [0, max];
};

export const renderBubble = (entry: { category: string; index: number; value: number }, index: number) => {
	let color = UiColors.white;

	if (entry.value >= 2 && entry.value < 10) {
		color = "#A4DEF0";
	} else if (entry.value <= 20) {
		color = "#96CEE6";
	} else if (entry.value <= 30) {
		color = "#89BFDC";
	} else if (entry.value <= 40) {
		color = "#7BAFD1";
	} else if (entry.value <= 50) {
		color = "#6E9FC7";
	} else if (entry.value <= 60) {
		color = "#6090BD";
	} else if (entry.value <= 70) {
		color = "#5380B3";
	} else if (entry.value <= 80) {
		color = "#4570A8";
	} else if (entry.value <= 90) {
		color = "#38619E";
	} else if (entry.value <= 100) {
		color = "#2A5194";
	}

	return <Cell key={`cell-${index}`} fill={color} />;
};

export const renderBubbleLabel = ({ x, y, stroke, value, formatter, ...props }: LabelProps) => {
	let color = UiColors.black;

	if (typeof value === "number" && value >= 20) {
		color = UiColors.white;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { verticalAnchor, ...positionProps } = getAttrsOfCartesianLabel({ x, y, stroke, value, ...props });

	return (
		<text className="recharts-label" {...positionProps} {...filterAxisProps(props)}>
			<tspan x={positionProps.x} dy="0.355em" fill={color}>
				{typeof formatter === "function" ? formatter(value) : value}
			</tspan>
		</text>
	);
};
