import { useEffect, useState } from "react";

import { getPageContent } from "../../utils/content/getPageContent";
import { PageContent, pageContent } from "../../config/routes/content";

export const usePageContent = (routeLabel: string | undefined) => {
	const [currentPageContent, setCurrentPageContent] = useState<PageContent | undefined>();

	useEffect(() => {
		if (!routeLabel || typeof routeLabel !== "string") {
			setCurrentPageContent(undefined);
			return;
		}

		const currentPageContent = getPageContent(pageContent, routeLabel);

		setCurrentPageContent(currentPageContent);
	}, [routeLabel]);

	return {
		blocks: [],
		...currentPageContent,
	} as PageContent;
};
