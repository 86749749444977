import { UiColors } from "../../../../config/ui/colors";

export const WEEE_FLOW_IN_THE_HOME_APPLIANCE_SECTOR_CHART_DATA_KEYS = [
	{
		name: "collected",
		translation: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.chart.dataKeys.collected",
		color: UiColors.charts.primary,
	},
	{
		name: "treatment",
		translation: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.chart.dataKeys.treatment",
		color: UiColors.charts.secondary,
	},
	{
		name: "recovered",
		translation: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.chart.dataKeys.recovered",
		color: UiColors.charts.tertiary,
	},
	{
		name: "reused",
		translation: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.chart.dataKeys.reused",
		color: UiColors.charts.quaternary,
	},
];
