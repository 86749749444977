export type AppliaPillarOneRouteNarrative =
	| "Production"
	| "Material flow"
	| "Use & consumption"
	| "Repair"
	| "Recycling"
	| "Secondary raw materials";
export type AppliaPillarTwoRouteNarrative = "Market trends" | "R&D";

export type AppliaPillarNarrative = AppliaPillarOneRouteNarrative | AppliaPillarTwoRouteNarrative;

export interface AppliaRoute {
	label: string;
	description?: string;
	children?: AppliaChildRoute[];
	pathName?: string;
	url?: string;
	pillarNumber?: number;
	status?: "todo" | "readyForApproval" | "approved" | "rejected" | "wontDo";
}

export interface AppliaChildRoute extends Omit<AppliaRoute, "children" | "pillarNumber"> {
	url: string;
	narrative?: AppliaPillarOneRouteNarrative | AppliaPillarTwoRouteNarrative;
}

export interface AppliaSiblingRoute {
	label: string;
	url: string;
	pillarNumber?: number;
	pathName?: string;
}

export const routes: AppliaRoute[] = [
	{
		label: "navigation.introduction.index",
		pathName: "introduction",
		children: [
			{
				url: "/foreword",
				label: "navigation.introduction.foreword",
				description: "pages.introduction.foreword.heroIntro",
				status: "approved",
			},
			{
				url: "/applia-direct-members",
				label: "navigation.introduction.appliaDirectMembers",
				description: "pages.introduction.appliaDirectMembers.heroIntro",
				status: "approved",
			},
			{
				url: "/national-associations",
				label: "navigation.introduction.nationalAssociations",
				description: "pages.introduction.nationalAssociations.heroIntro",
				status: "approved",
			},
			{
				url: "/applia-secretariat",
				label: "navigation.introduction.appliaSecretariat",
				description: "pages.introduction.appliaSecretariat.intro",
				status: "approved",
			},
			{
				url: "/europe-home-appliance-industry-in-2023",
				label: "navigation.introduction.europeHomeApplianceIndustryIn2023",
				description: "",
				status: "approved",
			},
			{
				url: "/products",
				label: "navigation.introduction.products",
				description: "",
				status: "approved",
			},
			{
				url: "/projects",
				label: "navigation.introduction.projects",
				description: "",
				status: "approved",
			},
			{
				url: "/policy-developments-in-2023",
				label: "navigation.introduction.policyDevelopmentsIn2023",
				description: "",
				status: "approved",
			},
			{
				url: "/roadmap-to-a-fair-sustainable-and-prosperous-european-society",
				label: "navigation.introduction.roadmapToAFairSustainableAndProsperousEuropeanSociety",
				description: "",
				status: "approved",
			},
			{
				url: "/heading-towards-the-2024-european-elections",
				label: "navigation.introduction.headingTowardsThe2024EuropeanElections",
				description: "",
				status: "approved",
			},
		],
	},
	{
		label: "navigation.pillar1.index",
		url: "/pillar/1",
		pillarNumber: 1,
		status: "approved",
		children: [
			{
				label: "navigation.pillar1.materialUseDuringTheProductionProcess",
				url: "/material-use-during-the-production-process",
				description: "",
				status: "approved",
				narrative: "Production",
			},
			{
				label: "navigation.pillar1.averageWaterConsumption",
				url: "/average-water-consumption-of-washing-machines-and-dishwashers",
				description: "",
				status: "approved",
				narrative: "Use & consumption",
			},
			{
				label: "navigation.pillar1.averageEnergyConsumption",
				url: "/average-energy-consumption-of-washing-machines-and-dishwashers",
				description: "",
				status: "approved",
				narrative: "Use & consumption",
			},
			{
				label: "navigation.pillar1.averageMaterialCompositionOfHomeAppliances",
				url: "/average-material-composition-of-home-appliances",
				description: "",
				status: "approved",
				narrative: "Material flow",
			},
			{
				label: "navigation.pillar1.quantitiesOfIndividualMaterialsUsedInHomeAppliances",
				url: "/quantities-of-individual-materials-used-in-home-appliances",
				description: "",
				status: "approved",
				narrative: "Material flow",
			},
			{
				label: "navigation.pillar1.averageMaterialCompositionOfLargeHomeAppliances",
				url: "/average-material-composition-of-large-home-appliances",
				description: "",
				status: "approved",
				narrative: "Material flow",
			},
			{
				label: "navigation.pillar1.averageMaterialCompositionOfSmallHomeAppliances",
				url: "/average-material-composition-of-small-home-appliances",
				description: "",
				status: "approved",
				narrative: "Material flow",
			},
			{
				label: "navigation.pillar1.plasticFlowsFromHomeAppliances",
				url: "/plastic-flows-from-home-appliances",
				description: "",
				status: "approved",
				narrative: "Material flow",
			},
			{
				label: "navigation.pillar1.routesOfRecycledPlastic",
				url: "/routes-of-recycled-plastic",
				description: "",
				status: "approved",
				narrative: "Material flow",
			},
			{
				label: "navigation.pillar1.energyConsumptionInTheHomeIn2021",
				url: "/energy-consumption-in-the-home-in-2021",
				description: "",
				status: "approved",
				narrative: "Use & consumption",
			},
			{
				label: "navigation.pillar1.hoardingOfWeee",
				url: "/hoarding-of-weee",
				description: "",
				status: "approved",
				narrative: "Recycling",
			},
			{
				label: "navigation.pillar1.weeeFlowInTheHomeApplianceSector",
				url: "/weee-flow-in-the-home-appliance-sector",
				description: "",
				status: "approved",
				narrative: "Recycling",
			},
			{
				label: "navigation.pillar1.materialsRecoveredFromWaste",
				url: "/materials-recovered-from-waste",
				description: "",
				status: "approved",
				narrative: "Secondary raw materials",
			},
		],
	},
	{
		label: "navigation.pillar2.index",
		url: "/pillar/2",
		pillarNumber: 2,
		status: "approved",
		children: [
			{
				label: "navigation.pillar2.electricalAndElectronicEquipmentPlacedOnTheEuMarket",
				url: "/electrical-and-electronic-equipment-placed-on-the-eu-market",
				description: "",
				status: "approved",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.europeanMarketHistoricalPicture",
				url: "/european-market-historical-picture",
				description: "",
				status: "approved",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.largeAppliancesSoldGlobally",
				url: "/large-appliances-sold-globally",
				description: "",
				status: "approved",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.unitsTradedInEurope",
				url: "/units-traded-in-europe",
				description: "",
				status: "readyForApproval",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.importAndExportOfHomeAppliancesInEuropeByContinent",
				url: "/import-and-export-of-home-appliances-in-europe-by-continent",
				description: "",
				status: "approved",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.top10ImportAndExportCountries",
				url: "/top-10-import-and-export-countries",
				description: "",
				status: "approved",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.evolutionOfImportAndExportOfHomeAppliancesInEurope",
				url: "/evolution-of-import-and-export-of-home-appliances-in-europe",
				description: "",
				status: "approved",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2023",
				url: "/applia-direct-members-manufacturing-sites-in-europe-in-2023",
				description: "",
				status: "approved",
				narrative: "Market trends",
			},
			{
				label: "navigation.pillar2.patentsFiledAndGrantedForHomeAppliances",
				url: "/patents-filed-and-granted-for-home-appliances",
				description: "",
				status: "approved",
				narrative: "R&D",
			},
			{
				label: "navigation.pillar2.investmentInRAndDByEuMemberStates",
				url: "/investment-in-r-and-d-by-eu-member-states",
				description: "",
				status: "approved",
				narrative: "R&D",
			},
			{
				label: "navigation.pillar2.investmentRDvsGDP",
				url: "/investment-in-r-and-d-by-eu-member-states-as-fraction-of-gdp",
				description: "",
				status: "approved",
				narrative: "R&D",
			},
		],
	},
];
