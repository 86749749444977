import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, XAxisProps, YAxis } from "recharts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CustomizedYAxisLabel } from "../shared/CustomizedYAxisLabel/CustomizedYAxisLabel";
import { CustomizedYAxisTick } from "../shared/CustomizedYAxisTick/CustomizedYAxisTick";
import { getTickFontSize, valueAccessor } from "../shared/HorizontalBarChart.helper";
import { UiColors } from "../../../config/ui/colors";
import { useBoolean } from "../../../hooks/useBoolean";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { useTextMeasurer } from "../../../hooks/charts/useTextMeasurer";

import { StackedHorizontalBarChartProps } from "./StackedHorizontalBarChart.type";

const StackedHorizontalBarChart: FC<StackedHorizontalBarChartProps> = ({
	barLabelFormatter,
	barLabelPosition = "inside",
	showBarLabel,
	data,
	dataKeys,
	height,
	XAxisProps,
	YAxisProps,
	rightMargin = 0,
	endOfBarLabelValueAccessor,
	tooltipUnit,
	XAxisLabel,
}) => {
	const { measureText } = useTextMeasurer(getTickFontSize(YAxisProps));
	const { t } = useTranslation();
	// const { width } = useWindowSize();
	const chartSize = useBreakpoint();
	const [showBarValues, { on, off }] = useBoolean(showBarLabel || true);

	// const [chartSize, setChartSize] = useState<ChartSize>("md");
	const [chartHeight, setChartHeight] = useState<number>(data.length * 47 + 30);
	const [chartYAxisLabelPosition, setChartYAxisLabelPosition] = useState<"inline" | "outside">("outside");
	const [leftMargin, setLeftMargin] = useState<number>(-60);

	const XAxisCombinedProps = useMemo<XAxisProps>(() => {
		return {
			...XAxisProps,
			axisLine: { stroke: UiColors.black, ...(typeof XAxisProps?.axisLine === "object" && { ...XAxisProps.axisLine }) },
			type: "number",
			tick: { fill: UiColors.black, ...(XAxisProps?.tick as object) },
			tickLine: false,
			interval: 0,
			tickMargin: 7.5,
			label: XAxisLabel,
		};
	}, [XAxisLabel, XAxisProps]);

	useEffect(() => {
		const values = data.map((value: any) => measureText(value?.name));

		switch (chartSize) {
			case "xs":
			case "sm":
			case "md":
				setLeftMargin(-60);
				setChartYAxisLabelPosition("inline");
				setChartHeight(data.length * 70 + 30);

				if (typeof showBarLabel === "boolean" && showBarLabel) {
					on();
				} else {
					off();
				}
				break;

			default:
				setLeftMargin(Math.max(...values));
				setChartHeight(data.length * 47 + 30);
				setChartYAxisLabelPosition("outside");
				if (typeof showBarLabel === "boolean" && !showBarLabel) {
					off();
				} else {
					on();
				}
				break;
		}
	}, [chartSize, data, measureText, on, off, showBarLabel]);

	const handleLabelFormat = useCallback(
		(value: any, name: string) => {
			const currentDataKey = dataKeys.find((dataKey) => dataKey.name === name);
			const label = typeof currentDataKey?.translation === "string" ? t(currentDataKey?.translation) : name;

			// If you return an array, the first entry will be the formatted "value",
			// and the second entry will be the formatted "name"

			if (tooltipUnit) {
				return [`${value}${tooltipUnit}`, label] as [string, string];
			}

			return [value, label];
		},
		[t, dataKeys, tooltipUnit],
	);

	return (
		<div className="w-full">
			<ResponsiveContainer width="99%" height={height || chartHeight}>
				<BarChart barSize={22} data={data} layout="vertical" margin={{ top: 20, right: rightMargin, bottom: 0, left: leftMargin }}>
					<CartesianGrid stroke={UiColors.charts.grid} horizontal={false} />

					<XAxis {...XAxisCombinedProps} />
					<YAxis
						{...YAxisProps}
						type="category"
						tickMargin={10}
						axisLine={{ stroke: UiColors.black, ...(typeof YAxisProps?.axisLine === "object" && { ...YAxisProps.axisLine }) }}
						{...(chartYAxisLabelPosition === "outside" && {
							tick: (props) => <CustomizedYAxisTick {...props} {...(typeof YAxisProps?.tick === "object" && { ...YAxisProps.tick })} />,
						})}
						{...(chartYAxisLabelPosition === "inline" && {
							tick: false,
						})}
						tickLine={false}
						interval={0}
					/>

					{dataKeys.map((dataKey, index) => (
						<Bar key={dataKey.name} dataKey={dataKey.name} stackId="a" fill={dataKey.color} isAnimationActive={false}>
							{["xs", "sm", "md"].includes(chartSize || "") && index === 0 && (
								<LabelList
									transform="translate(7.5 -7.5)"
									fill={UiColors.black}
									fontSize={12}
									valueAccessor={valueAccessor("name")}
									position="insideLeft"
									fontWeight="bold"
									content={(props) => <CustomizedYAxisLabel {...props} position="insideLeft" />}
								/>
							)}
							{showBarValues && (
								<LabelList
									formatter={typeof barLabelFormatter === "function" ? (props: any) => barLabelFormatter(props) : undefined}
									fill={UiColors.white}
									position={barLabelPosition}
									fontSize={11}
									valueAccessor={valueAccessor(dataKey.name)}
								/>
							)}
							{index === dataKeys.length - 1 && typeof endOfBarLabelValueAccessor === "function" && (
								<LabelList
									fill={UiColors.black}
									position="right"
									fontSize={12}
									valueAccessor={(data: any) => endOfBarLabelValueAccessor(data)}
								/>
							)}
						</Bar>
					))}

					{!showBarValues && (
						<Tooltip isAnimationActive={false} formatter={handleLabelFormat} cursor={{ fill: "rgba(255, 255, 255, 0.2)" }} />
					)}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export { StackedHorizontalBarChart };
