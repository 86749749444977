import { AlertProps } from "../Alert/Alert.type";
import { AppliaRoute } from "../../config/routes/routes";

export const getAlertType = (status: AppliaRoute["status"]): AlertProps["type"] => {
	switch (status) {
		case "approved":
			return "success";

		case "rejected":
		case "wontDo":
			return "error";

		default:
			return "info";
	}
};

export const getAlertTitle = (status: AppliaRoute["status"]): string => {
	switch (status) {
		case "readyForApproval":
			return "Content ready for approval";

		case "approved":
			return "Content approved by client";

		case "rejected":
			return "Content rejected by client";

		case "wontDo":
			return "Content page is flagged as won't do! ⛔";

		default:
			return "Content needs to be written yet";
	}
};

export const getAlertBody = (status: AppliaRoute["status"]): string => {
	switch (status) {
		case "readyForApproval":
			return "The content on this page is ready to be verified by the client.";

		case "approved":
			return "The content on this page has been reviewed and approved by the client. Good job! 🎉";

		case "rejected":
			return "The content is rejected by the client. Please adjust the content and submit for approval.";

		case "wontDo":
			return "This page is flagged as won't do. We did not receive content on time to create this page.";

		default:
			return "Please keep in mind that the content on this page is not final and is under construction.";
	}
};
