import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/vectors/content/pillar-2/investment-in-r-and-d-by-eu-member-states/investment-in-r-and-d-by-eu-member-states-desktop.svg";
import Mobile from "../../../../assets/vectors/content/pillar-2/investment-in-r-and-d-by-eu-member-states/investment-in-r-and-d-by-eu-member-states-mobile.svg";

const InvestmentInRAndDByEuMemberStates: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col items-center justify-center">
			<img
				className="hidden sm:block w-full max-w-6xl"
				src={Desktop}
				alt={t("pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.visual.alt") as string}
			/>
			<img
				className="block sm:hidden w-full max-w-md self-start"
				src={Mobile}
				alt={t("pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.visual.alt") as string}
				title={t("pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.visual.alt") as string}
			/>
		</div>
	);
};

export { InvestmentInRAndDByEuMemberStates };
