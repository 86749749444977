import classNames from "classnames";
import { forwardRef, ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { SidebarItemProps } from "./SidebarItem.type";
import { getSidebarItemClassName } from "./SidebarItem.util";

const SidebarItem = forwardRef<HTMLLIElement, SidebarItemProps>(({ children, to, ...props }, ref) => {
	return (
		<li ref={ref} className="sidebar__scrollable-item">
			<NavLink
				end
				className={(props) =>
					classNames("flex items-center justify-center rounded-lg p-2 text-base font-normal", getSidebarItemClassName(props))
				}
				to={to}
				{...props}
			>
				<span className="pr-3 pl-5 flex-1 items-start">{children as ReactNode}</span>
			</NavLink>
		</li>
	);
});

export { SidebarItem };
