import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useBreakpoint } from "../../../hooks/useBreakpoint";

import { socialLinks } from "./SocialRow.const";

const SocialRow: FC = () => {
	const { t } = useTranslation();
	const chartSize = useBreakpoint();

	const imageSize = useMemo(() => {
		switch (chartSize) {
			case "xs":
			case "sm":
				return 30;
			case "lg":
			case "md":
				return 25;
			default:
				return 20;
		}
	}, [chartSize]);

	return (
		<div className="flex flex-col items-center">
			<span className="text-gray-600 mb-3 lg:hidden">{t("navigation.followUs")}</span>

			<div className="flex lg:w-full w-3/4 lg:mx-0 mx-auto justify-between sm:justify-center gap-4 lg:pr-4">
				{socialLinks.map((socialLink) => {
					return (
						<a key={socialLink.link} href={socialLink.link} target="_blank" rel="noreferrer" className="p-2 hover:bg-slate-100 rounded-md">
							<socialLink.logo className="text-gray-600" width={imageSize} />
						</a>
					);
				})}
			</div>
		</div>
	);
};

export { SocialRow };
