import { FC, useMemo } from "react";
import { CartesianGrid, Dot, DotProps, LabelList, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis, ZAxis } from "recharts";

import { BubbleChartProps } from "./BubbleChart.type";
import { DEFAULT_COLOR, LABEL_COLOR } from "./BubbleChart.const";

import "./BubbleChart.css";

const CenterDot: FC<DotProps> = ({ cx, cy, r = 3 }) => {
	return <Dot cx={cx} cy={cy} r={r} id="centerDot" />;
};

const BubbleChart: FC<BubbleChartProps> = ({ data, max, width, height, margin, YAxisProps, XAxisProps, labelFormatter }) => {
	const domain = useMemo(() => {
		if (!max) {
			const maxValue = Math.max(...data.map((bubble) => bubble.value));
			return [0, maxValue];
		} else return [0, max];
	}, [data, max]);

	const chartData = useMemo(() => {
		return data
			.sort((a, b) => a.value - b.value)
			.map((bubble, index) => {
				return { ...bubble, index: bubble.index ? bubble.index : 1, label: `${bubble.label}\n#${data.length - index}` };
			});
	}, [data]);

	const lineColor = useMemo(() => {
		if (!YAxisProps) {
			return;
		}
		if (!YAxisProps.tickLine || typeof YAxisProps.tickLine === "boolean") {
			return DEFAULT_COLOR;
		}
		return YAxisProps.tickLine.stroke ? YAxisProps.tickLine.stroke : DEFAULT_COLOR;
	}, [YAxisProps]);

	return (
		<div className="bubble-chart w-full">
			<ResponsiveContainer height={height} width="100%">
				<ScatterChart width={width} height={height} margin={margin}>
					<CartesianGrid strokeWidth={YAxisProps?.strokeWidth} stroke={lineColor} vertical={false} />

					<XAxis {...XAxisProps} type="number" dataKey="index" tick={false} tickLine={false} axisLine={false} />
					<YAxis {...YAxisProps} type="category" dataKey="label" axisLine={false} />
					<ZAxis type="number" dataKey="value" domain={domain} range={[0, 10000]} />

					<Scatter data={chartData} strokeWidth={2}>
						<LabelList
							dataKey="value"
							position="centerBottom"
							transform="translate(0, 16)"
							formatter={labelFormatter}
							width={100}
							fontWeight={600}
							fill={LABEL_COLOR}
						/>
					</Scatter>
					<Scatter data={chartData} shape={<CenterDot />} />
				</ScatterChart>
			</ResponsiveContainer>
		</div>
	);
};

export { BubbleChart };
