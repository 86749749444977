import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { BarChartData } from "../../../../types/recharts/barChart";
import { Legend } from "../../../Charts/Legend/Legend";
import { LegendProps } from "../../../Charts/Legend/Legend.type";
import { StackedHorizontalBarChart } from "../../../Charts/StackedHorizontalBarChart/StackedHorizontalBarChart";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";
import Chart1 from "../../../../assets/images/content/pillar-1/material-recovered-from-waste/materials-recovered-chart-1-mobile.webp";
import Chart2 from "../../../../assets/images/content/pillar-1/material-recovered-from-waste/materials-recovered-chart-2-mobile.webp";

import { MATERIALS_RECOVERED_FROM_WASTE_CHART_DATA_KEYS } from "./MaterialsRecoveredFromWaste.const";
import { MaterialsRecoveredFromWasteData, MaterialsRecoveredFromWasteProps } from "./MaterialsRecoveredFromWaste.type";

const MaterialsRecoveredFromWaste: FC<MaterialsRecoveredFromWasteProps> = ({ data }) => {
	const { t } = useTranslation();
	const chartSize = useBreakpoint();

	const [legendDirection, setLegendDirection] = useState<LegendProps["direction"]>("horizontal");

	const translatedBarChartData = useMemo<BarChartData>(() => {
		return data.map((dataEntry) => ({
			...dataEntry,
			name: t(`pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.chart.yAxisLabels.${dataEntry.name}`),
		}));
	}, [data, t]);

	const legendData = useMemo(() => {
		return MATERIALS_RECOVERED_FROM_WASTE_CHART_DATA_KEYS.map((dataKey) => {
			const label = typeof dataKey?.translation === "string" ? t(dataKey?.translation) : dataKey.name;

			return {
				color: dataKey.color,
				label: label,
			};
		});
	}, [t]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
			case "sm":
				setLegendDirection("vertical");
				break;

			case "md":
			case "lg":
			case "xl":
			default:
				setLegendDirection("horizontal");
				break;
		}
	}, [chartSize]);

	const endOfBarLabelValueAccessor = useCallback((entry: MaterialsRecoveredFromWasteData): string => {
		const total =
			entry?.aluminium +
				entry?.concrete +
				entry?.copper +
				entry?.glass +
				entry?.others +
				entry?.plastics +
				entry?.polyurethane +
				entry?.steel || 0;
		return `${new Intl.NumberFormat("nl-BE").format(total)}Mt`;
	}, []);

	return (
		<div className="flex flex-col gap-8">
			<div className="grid w-full 2xl:w-3/4 self-left grid-cols-1 sm:grid-cols-2 gap-10 md:gap-6 2xl:gap-10">
				<div className="flex flex-col gap-4">
					<div className="grow">
						<p className="h4">{t("pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual.industryFlows.title")}</p>
					</div>
					<img
						className="self-start"
						src={Chart1}
						alt={t("pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual.industryFlows.alt") as string}
						title={t("pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual.industryFlows.alt") as string}
					/>
				</div>
				<div className="flex flex-col gap-4">
					<div className="grow">
						<p className="h4">{t("pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual.complementaryFlows.title")}</p>
					</div>
					<img
						className="self-start"
						src={Chart2}
						alt={t("pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual.complementaryFlows.alt") as string}
						title={t("pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual.complementaryFlows.alt") as string}
					/>
				</div>
			</div>

			<div className="self-stretch md:self-center max-w-4xl mt-4">
				<Legend items={legendData} direction={legendDirection} />
			</div>

			<StackedHorizontalBarChart
				data={translatedBarChartData}
				dataKeys={MATERIALS_RECOVERED_FROM_WASTE_CHART_DATA_KEYS}
				YAxisProps={{
					dataKey: "name",
					padding: { top: 10, bottom: 10 },
					fontSize: 12,
				}}
				XAxisProps={{ unit: "Mt", tickCount: 5, tick: { fontSize: 12 } }}
				showBarLabel={false}
				tooltipUnit="Mt"
				endOfBarLabelValueAccessor={endOfBarLabelValueAccessor}
			/>
		</div>
	);
};

export { MaterialsRecoveredFromWaste };
