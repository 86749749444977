import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { HeaderBlockProps } from "./HeaderBlock.type";

const HeaderBlock: FC<HeaderBlockProps> = ({ data, id }) => {
	const { t } = useTranslation();
	const { text, align = "left", type } = data;

	const TagName = type;

	return (
		<div id={id}>
			{typeof text === "string" && (
				<TagName
					className={classNames("leading-relaxed", { "text-primary": type === "h2" })}
					style={{ textAlign: align }}
					dangerouslySetInnerHTML={{ __html: t(text) }}
				/>
			)}
		</div>
	);
};

export { HeaderBlock };
