import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Select } from "../../../../Select/Select";

import { categories, units, years } from "./CustomFilterDropdown.const";
import { CustomFilterProps, CustomFilterInput } from "./CustomFilterDropdown.type";

const CustomFilterDropdown: FC<CustomFilterProps> = ({ onChange, defaultValue }) => {
	const { t } = useTranslation();

	const { register, watch } = useForm<CustomFilterInput>({
		defaultValues: {
			year: defaultValue?.year,
			category: defaultValue?.category,
			unit: defaultValue?.unit,
		},
	});

	useEffect(() => {
		if (!onChange) {
			return;
		}
		const subscription = watch((value) => {
			const { year, category, unit } = value;
			if (year && category && unit) {
				onChange({ year, category, unit });
			}
		});
		return () => subscription.unsubscribe();
	}, [onChange, watch]);

	return (
		<form className="flex md:flex-row flex-col gap-5">
			<div className="flex flex-col">
				<label className="font-bold mb-2" htmlFor="year-box">
					{t("general.filter.year")}
				</label>
				<Select id="year-box" options={years} {...register("year", { required: true })} />
			</div>
			<div className="flex flex-col">
				<label className="font-bold mb-2" htmlFor="category-box">
					{t("general.filter.category")}
				</label>
				<Select id="category-box" options={categories} {...register("category", { required: true })} />
			</div>
			<div className="flex flex-col">
				<label className="font-bold mb-2" htmlFor="unit-box">
					{t("general.filter.unit")}
				</label>
				<Select id="unit-box" options={units} {...register("unit", { required: true })} />
			</div>
		</form>
	);
};

export { CustomFilterDropdown };
