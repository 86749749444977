import { FC } from "react";

import { UiColors } from "../../../../config/ui/colors";

import { CustomizedYAxisTickProps } from "./CustomizedYAxisTick.type";

const CustomizedYAxisTick: FC<CustomizedYAxisTickProps> = ({ x, y, fontSize, payload }) => (
	<g transform={`translate(${x},${y})`}>
		<text fontSize={`${fontSize || 14}px`} x={0} y={0} dy={0} textAnchor="end" alignmentBaseline="middle" fill={UiColors.black}>
			{payload.value}
		</text>
	</g>
);

export { CustomizedYAxisTick };
