import { FC, useEffect, useLayoutEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

import { ReactComponent as Cross } from "../../../assets/vectors/icons/x.svg";
import { ReactComponent as Menu } from "../../../assets/vectors/icons/menu.svg";
import { routes } from "../../../config/routes/routes";
import { Sidebar } from "../Sidebar/Sidebar";
import { SocialRow } from "../SocialRow/SocialRow";
import { UiBreakpoints } from "../../../config/ui/breakpoints";
import { useBoolean } from "../../../hooks/useBoolean";
import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll";
import { usePrevious } from "../../../hooks/usePrevious";
import { useWindowSize } from "../../../hooks/useWindowSize";
import logo from "../../../assets/vectors/logo/applia_logo_gradient.svg";

const Header: FC = () => {
	const [isNavOpen, { toggle, off }] = useBoolean(false);
	const { width } = useWindowSize(50);
	const { pathname } = useLocation();
	const previousWidth = usePrevious<number | undefined>(width);

	useLockBodyScroll(width < UiBreakpoints.lg && isNavOpen);

	useEffect(() => {
		off();
	}, [off, pathname]);

	useLayoutEffect(() => {
		if (!width || width === previousWidth) {
			return;
		}

		if (width > UiBreakpoints.lg) {
			off();
		}
	}, [isNavOpen, off, previousWidth, width]);

	return (
		<div
			className={classNames("fixed z-50 left-0 top-0 right-0", {
				"h-[100svh]": isNavOpen,
			})}
		>
			<div className="flex flex-row items-center justify-between flex-wrap bg-white px-6 py-3 h-20 border-b border-slate-100 gap-6">
				<NavLink to="/" className="flex items-center flex-shrink-0">
					<img src={logo} className="w-28" alt="APPLiA logo" />
				</NavLink>
				<div className="hidden lg:block">
					<SocialRow />
				</div>
				<div className="block lg:hidden">
					<button
						onClick={toggle}
						aria-expanded={isNavOpen}
						className="flex items-center text-slate-500 hover:text-slate-300 active:text-slate-400 transition-colors"
					>
						{isNavOpen ? <Cross fill="current" /> : <Menu fill="current" />}
					</button>
				</div>
			</div>

			{isNavOpen ? (
				<div className="overflow-y-auto isolate no-bg-scrollbar grow max-h-[calc(100svh-5rem)] bg-white">
					<Sidebar routes={routes} mobile />
				</div>
			) : null}
		</div>
	);
};

export { Header };
