import { FC, useCallback, useEffect, useMemo } from "react";
import { Area, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer, ComposedChart } from "recharts";

import { AreaChartProps, CustomToolTipProps } from "./AreaChart.type";

import "./AreaChart.css";

const TooltipValues: FC<CustomToolTipProps> = ({ payload, onChange }) => {
	const toolTipValue = useMemo(() => {
		if (!payload || !payload[0]) {
			return;
		}
		return payload[0].payload.name;
	}, [payload]);

	useEffect(() => onChange(toolTipValue), [onChange, toolTipValue]);
	return null;
};

const AreaChart: FC<AreaChartProps> = ({ data, width, height, margin, XAxisProps, YAxisProps, keys, onChange, stacked = false }) => {
	const handleChange = useCallback(
		(value: string) => {
			onChange(+value);
		},
		[onChange],
	);

	return (
		<div className="area-chart w-full">
			<ResponsiveContainer width={width || "99%"} height={height}>
				<ComposedChart data={data} margin={margin}>
					<XAxis {...XAxisProps} dataKey="name" />
					<YAxis {...YAxisProps} />
					<CartesianGrid vertical={false} />
					<Tooltip content={<TooltipValues onChange={handleChange} />} />
					{keys.map((key, index) => {
						return (
							<Area
								dot={{ strokeWidth: 5 }}
								key={`${key.name}-visual`}
								fillOpacity={0.25}
								dataKey={key.name}
								stroke={key.color}
								fill={key.color}
								strokeWidth={2}
								stackId={stacked ? 1 : index}
							/>
						);
					})}
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
};

export { AreaChart };
