import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EUTradeObject } from "../../../Charts/shared/chart.type";
import { ReactComponent as EuropeanFlag } from "../../../../assets/vectors/logo/european-union.svg";
import { SankeyDiagram } from "../../../Charts/SankeyDiagram/SankeyDiagram";

import { CustomFilterDropdown } from "./CustomFilterDropdown/CustomFilterDropdown";
import { CustomFilterInput } from "./CustomFilterDropdown/CustomFilterDropdown.type";
import { getTotalValue, getUnit, parseLinks } from "./ImportAndExportOfHomeAppliancesInEuropeByContinent.helper";
import { nodesData } from "./ImportAndExportOfHomeAppliancesInEuropeByContinent.const";
import { ImportAndExportOfHomeAppliancesInEuropeByContinentProps } from "./ImportAndExportOfHomeAppliancesInEuropeByContinent.type";

const ImportAndExportOfHomeAppliancesInEuropeByContinent: FC<ImportAndExportOfHomeAppliancesInEuropeByContinentProps> = ({ data }) => {
	const [year, setYear] = useState<string>("2023");
	const [category, setCategory] = useState<string>("Total");
	const [unit, setUnit] = useState<"valueInEuros" | "quantityInKg">("valueInEuros");

	const { t } = useTranslation();

	const importSankeyData = useMemo(() => {
		const importData = data.filter((tradeObject) => tradeObject.flowName === "Import") as EUTradeObject[];
		if (!importData || importData.length <= 0 || !year || !category || !unit) {
			return;
		}
		const filteredImportData = importData.filter((tradeObject) => tradeObject.period === year && tradeObject.familyName === category);

		if (!filteredImportData || filteredImportData.length <= 0) {
			return;
		}

		const links = parseLinks(filteredImportData, "right", unit);
		const nodes = nodesData.filter((node) => (node.label === "Total" ? true : links.find((link) => link.source === node.label)));
		return { links, nodes };
	}, [category, data, unit, year]);

	const exportSankeyData = useMemo(() => {
		const exportData = data.filter((tradeObject) => tradeObject.flowName === "Export") as EUTradeObject[];
		if (!exportData || exportData.length <= 0 || !year || !category || !unit) {
			return;
		}

		const filteredImportData = exportData.filter((tradeObject) => tradeObject.period === year && tradeObject.familyName === category);

		if (!filteredImportData || filteredImportData.length <= 0) {
			return;
		}

		const links = parseLinks(filteredImportData, "left", unit);
		const nodes = nodesData.filter((node) => (node.label === "Total" ? true : links.find((link) => link.target === node.label)));
		return { links, nodes };
	}, [category, data, unit, year]);

	const largestLinkValue = useMemo(() => {
		if (!importSankeyData || !exportSankeyData) {
			return;
		}
		const totalImportLinkValue = getTotalValue(importSankeyData.links);
		const totalExportLinkValue = getTotalValue(exportSankeyData.links);

		return totalImportLinkValue >= totalExportLinkValue ? totalImportLinkValue : totalExportLinkValue;
	}, [importSankeyData, exportSankeyData]);

	const handleDropdownChange = useCallback((value: CustomFilterInput) => {
		const { year: selectedYear, category: selectedCategory, unit: selectedUnit } = value;
		if (!selectedYear || !selectedCategory || !selectedUnit) {
			return;
		}
		setYear(`${selectedYear}`);
		setCategory(selectedCategory);
		setUnit(getUnit(selectedUnit));
	}, []);

	const tickFormatter = useCallback(
		(tick: string) => {
			const value = new Intl.NumberFormat("nl-BE").format(Math.ceil(+tick / 10000) / 100);
			return unit === "valueInEuros" ? `${value} M€` : `${value} MKg`;
		},
		[unit],
	);

	return (
		<>
			<div className="mb-5">
				<CustomFilterDropdown
					onChange={handleDropdownChange}
					defaultValue={{ year: +year, category, unit: unit === "valueInEuros" ? "Euros" : "Quantity in kg" }}
				/>
			</div>
			<div className="flex lg:flex-row flex-col lg:gap-2 gap-12">
				{importSankeyData && (
					<div className="flex-1 flex flex-col lg:justify-start justify-end">
						<div className="flex flex-col justify-end items-end mr-2">
							<EuropeanFlag width={45} height={45} />
							<h3 className="xl:text-2xl lg:text-xl md:text-3xl sm:text-2xl text-lg text-[#224b8c] uppercase font-bold">
								{t("pages.pillar.pillar2.importAndExportOfHomeAppliancesInEuropeByContinent.sankeyLabels.import")}
							</h3>
							<span className="2xl:text-xl lg:text-base md:text-2xl sm:text-lg text-base font-light">
								{tickFormatter(`${getTotalValue(importSankeyData.links)}`)}
							</span>
						</div>
						<SankeyDiagram
							links={importSankeyData.links}
							nodes={importSankeyData.nodes}
							align="right"
							width={1000}
							height={1000}
							maxValue={largestLinkValue}
							rangeBoundary={getTotalValue(importSankeyData.links)}
							margin={{ top: 0, left: 0, right: 10, bottom: 40 }}
							tickFormatter={tickFormatter}
						/>
					</div>
				)}

				{exportSankeyData && (
					<div className="flex-1 flex flex-col justify-start">
						<div className="flex flex-col justify-start items-start ml-2">
							<EuropeanFlag width={45} height={45} />
							<h3 className="xl:text-2xl lg:text-xl md:text-3xl sm:text-2xl text-lg text-[#224b8c] uppercase font-bold">
								{t("pages.pillar.pillar2.importAndExportOfHomeAppliancesInEuropeByContinent.sankeyLabels.export")}
							</h3>
							<span className="2xl:text-xl lg:text-base md:text-2xl sm:text-lg text-base font-light">
								{tickFormatter(`${getTotalValue(exportSankeyData.links)}`)}
							</span>
						</div>
						<SankeyDiagram
							links={exportSankeyData.links}
							nodes={exportSankeyData.nodes}
							align="left"
							width={1000}
							height={1000}
							maxValue={largestLinkValue}
							rangeBoundary={getTotalValue(exportSankeyData.links)}
							margin={{ top: 0, left: 10, right: 0, bottom: 40 }}
							tickFormatter={tickFormatter}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export { ImportAndExportOfHomeAppliancesInEuropeByContinent };
