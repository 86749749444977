import { AppliaRoute } from "./../../../config/routes/routes";

export const getRouteCollapsible = (routes: AppliaRoute[], currentRoute: string) =>
	routes.find((route) => {
		if (route?.url && route?.url === currentRoute) {
			return true;
		}

		if (!Array.isArray(route?.children)) {
			return false;
		}

		return route?.children.some((child) => {
			if (route?.url) {
				return `${route?.url}${child.url}` === currentRoute;
			}

			if (route?.pathName) {
				return `/${route?.pathName}${child.url}` === currentRoute;
			}

			return child.url === currentRoute;
		});
	});
