import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { SidebarCollapsibleProps } from "./SidebarCollapsible.type";

const SidebarCollapsible = forwardRef<HTMLButtonElement, SidebarCollapsibleProps>(
	({ active = false, children, label, onClick, url, open = false, title }, ref) => {
		const navigate = useNavigate();

		const handleButtonClick = useCallback(() => {
			if (url) {
				navigate(url);
			}

			if (typeof onClick === "function") {
				onClick();
			}
		}, [navigate, onClick, url]);

		return (
			<ul className="mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0">
				<li>
					<button
						className={classNames(
							"group flex w-full items-center justify-between rounded-lg p-2 text-base font-normal transition duration-75 sidebar__scrollable-item",
							{
								"text-gray-900 hover:bg-gray-100": !active,
								"bg-blue-100 hover:bg-blue-100 text-blue-800": active,
							},
						)}
						title={title}
						onClick={handleButtonClick}
						ref={ref}
					>
						<span className="ml-3 flex-1 text-left shrink" dangerouslySetInnerHTML={{ __html: label }}></span>
						<svg
							stroke="currentColor"
							fill="currentColor"
							strokeWidth="0"
							viewBox="0 0 20 20"
							aria-hidden="true"
							className={classNames("h-6 w-6 shrink-0 ml-3 transition-transform hidden lg:block", {
								"-rotate-90": !open,
							})}
							height="1em"
							width="1em"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
				</li>
				<ul className="space-y-2 py-2" hidden={!open}>
					{children}
				</ul>
			</ul>
		);
	},
);

export { SidebarCollapsible };
