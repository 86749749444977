export const EEE_PLACED_ON_THE_EU_MARKET_CHART_DATA_KEYS = [
	{
		name: "homeAppliances",
		translation: "pages.pillar.pillar2.electricalAndElectronicEquipmentPlacedOnTheEuMarket.blocks.chart.dataKeys.homeAppliances",
		color: "#4290ce",
	},
	{
		name: "otherEEE",
		translation: "pages.pillar.pillar2.electricalAndElectronicEquipmentPlacedOnTheEuMarket.blocks.chart.dataKeys.otherEEE",
		color: "#a3a2a2",
	},
];