import { FC, useEffect, useState } from "react";

import { ChartSize } from "../../../../types/charts/size";
import { HorizontalBarChart } from "../../../Charts/HorizontalBarChart/HorizontalBarChart";
import { UiColors } from "../../../../config/ui/colors";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import { DIRECT_MEMBERS_MANUFACTURING_SITES_IN_EUROPE_CHART_DATA_KEYS } from "./DirectMembersManufacturingSitesInEurope.const";
import { DirectMembersManufacturingSitesInEuropeProps } from "./DirectMembersManufacturingSitesInEurope.type";

const DirectMembersManufacturingSitesInEurope: FC<DirectMembersManufacturingSitesInEuropeProps> = ({ data }) => {
	const { width } = useWindowSize();

	const [chartSize, setChartSize] = useState<ChartSize>("md");

	const [chartTickCount, setChartTickCount] = useState<number>(11);
	const [axisFontSize, setAxisFontSize] = useState(14);

	useEffect(() => {
		if (width < 475) {
			setChartSize("xs");
		} else if (width < 576) {
			setChartSize("sm");
		} else if (width < 960) {
			setChartSize("md");
		} else {
			setChartSize("lg");
		}
	}, [width]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setAxisFontSize(11);
				setChartTickCount(6);
				break;

			case "sm":
				setAxisFontSize(11);
				setChartTickCount(6);
				break;

			default:
				setAxisFontSize(14);
				setChartTickCount(6);
				break;
		}
	}, [chartSize]);

	return (
		<HorizontalBarChart
			data={data}
			dataKeys={DIRECT_MEMBERS_MANUFACTURING_SITES_IN_EUROPE_CHART_DATA_KEYS}
			XAxisProps={{
				orientation: "top",
				domain: [0, 25],
				tickCount: chartTickCount,
				fontSize: axisFontSize,
			}}
			YAxisProps={{
				dataKey: "name",
				padding: { top: 15, bottom: 0 },
				fontSize: axisFontSize,
			}}
			barLabelPosition="right"
			barLabelColor={UiColors.black}
		/>
	);
};

export { DirectMembersManufacturingSitesInEurope };
