import { useTranslation } from "react-i18next";

import { Container } from "../../../components/Container/Container";
import { Seo } from "../../../components/Seo/Seo";

export const NotFoundErrorPage = () => {
	const { t } = useTranslation();

	return (
		<>
			<Seo title={t("pages.error.404.seoTitle")} description={t("pages.error.404.seoDescription") as string} />
			<Container>
				<h1 className="mb-5">{t("pages.error.404.oops")}</h1>
				<p className="text-base">{t("pages.error.404.text")}</p>
			</Container>
		</>
	);
};
