import { ForwardRefRenderFunction, forwardRef } from "react";

import { ReactComponent as ChevronDown } from "../../assets/vectors/icons/chevron-down.svg";

import { SelectProps } from "./Select.type";

const SelectForwardComponent: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = ({ id, options, ...rest }, ref) => {
	return (
		<div className="relative w-full">
			<select
				id={id}
				className="appearance-none bg-white border border-gray-300 rounded-md px-4 py-2 pr-12 leading-tight focus:outline-none focus:border-primary w-full md:w-auto"
				ref={ref}
				{...rest}
			>
				{options.map((value) => (
					<option key={`${id}-${value}`}>{value}</option>
				))}
			</select>
			<div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
				<ChevronDown width="14" height="14" />
			</div>
		</div>
	);
};

const Select = forwardRef<HTMLSelectElement, SelectProps>(SelectForwardComponent);

export { Select };
