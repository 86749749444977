import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppliaChildRoute, AppliaPillarNarrative } from "../../../config/routes/routes";
import { Container } from "../../../components/Container/Container";
import { ContentBuilder } from "../../../components/ContentBuilder/ContentBuilder";
import { ContentFooter } from "../../../components/ContentFooter/ContentFooter";
import { ContentStatus } from "../../../components/ContentStatus/ContentStatus";
import { Narrative } from "../../../components/Narrative/Narrative";
import { PageContentSource } from "../../../config/routes/content";
import { ReactComponent as Download } from "../../../assets/vectors/icons/download.svg";
import { Seo } from "../../../components/Seo/Seo";
import { usePageContent } from "../../../hooks/content/usePageContent";
import { useRouteInfo } from "../../../hooks/route/useRouteInfo";

const PillarDetail: FC = () => {
	const { routeData, title, description, routeParent } = useRouteInfo();
	const { t } = useTranslation();

	const { blocks, pageIntro, source, pdf } = usePageContent(routeData?.label);

	const hasSourceUrlArray = Array.isArray(source);
	const hasSource = typeof source === "object" && !hasSourceUrlArray && typeof (source as PageContentSource).label.trim() === "string";
	const hasSourceUrl = typeof source === "object" && typeof (source as PageContentSource)?.url === "string";

	return (
		<>
			<Seo
				title={title}
				description={typeof description === "string" && description.trim().length > 0 ? (t(description) as string) : undefined}
			/>

			<article className="break-words">
				<Container contentPaddingClasses="px-6 pt-8 pb-0 md:px-9 md:pt-9 xl:px-14 xl:pt-14">
					<div className="flex flex-col gap-4 w-full max-w-6xl mx-auto">
						<NavLink className="text-blue-700 self-start" to={`/pillar/${routeParent?.pillarNumber}`}>
							{t("pages.pillar.pillar")} {routeParent?.pillarNumber} {">"}
						</NavLink>

						<h1 className="text-3xl">{t(routeData?.label as string)}</h1>
						<div className="flex gap-3 items-center">
							{typeof (routeData as AppliaChildRoute)?.narrative !== "undefined" ? (
								<Narrative type={(routeData as AppliaChildRoute).narrative as AppliaPillarNarrative} />
							) : null}
							{/* Download visuals in pdf */}
							{typeof pdf !== "undefined" && (
								<a
									className="flex items-center gap-2 text-primary text-sm hover:underline hover:text-primary-hover"
									href={`${window.location.origin}${pdf?.path}`}
									download={pdf.filename ? pdf.filename : true}
								>
									<Download width={18} />
									{t("cta.pages.downloadPagePdf")}
								</a>
							)}
						</div>
					</div>
				</Container>

				<Container contentClassName="!py-0">
					<div className="w-full max-w-6xl mx-auto">
						<ContentStatus status={routeData?.status} />
						{typeof pageIntro !== "undefined" ? <p className="text-xl leading-relaxed">{t(pageIntro)}</p> : null}
						<ContentBuilder blocks={blocks} />
						{hasSource && !hasSourceUrlArray && (
							<div className="flex mt-2">
								<p className="text-gray-500 italic text-sm">
									{t("pages.pillar.source")}:{" "}
									{hasSourceUrl ? (
										<a target="_blank" href={source.url} rel="noreferrer" className="text-primary hover:text-primary-hover hover:underline">
											{source.label}
										</a>
									) : (
										source.label
									)}
								</p>
							</div>
						)}
						{hasSourceUrlArray && (
							<div className="flex mt-2">
								<p className="text-gray-500 italic text-sm">
									{t("pages.pillar.source")}:{" "}
									{source.map((sourceChild, idx: number) => (
										<span key={sourceChild.url}>
											<a
												target="_blank"
												href={sourceChild.url}
												rel="noreferrer"
												className="text-primary hover:text-primary-hover hover:underline"
											>
												{sourceChild.label}
											</a>
											{idx + 1 !== source.length && <span>, </span>}
										</span>
									))}
								</p>
							</div>
						)}
						<ContentFooter />
					</div>
				</Container>
			</article>
		</>
	);
};

export { PillarDetail };
