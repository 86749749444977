import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ChartSize } from "../../../../types/charts/size";
import { MatrixDiagram } from "../../../Charts/MatrixDiagram/MatrixDiagram";
import { UiBreakpoints } from "../../../../config/ui/breakpoints";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import { AvgMaterialCompositionLargeHomeAppliancesProps } from "./AvgMaterialCompositionLargeHomeAppliances.type";
import { AvgMaterialCompositionLargeHomeAppliancesTooltip } from "./AvgMaterialCompositionLargeHomeAppliancesTooltip/AvgMaterialCompositionLargeHomeAppliancesTooltip";

const AvgMaterialCompositionLargeHomeAppliances: FC<AvgMaterialCompositionLargeHomeAppliancesProps> = ({ data }) => {
	const { t } = useTranslation();
	const { width } = useWindowSize();

	const [chartSize, setChartSize] = useState<ChartSize>("md");
	const [range, setRange] = useState<[number, number]>([0, 4000]);
	const [titleClassNames, setTitleClassNames] = useState<string>("break-words");

	const xAxisLabels = useMemo(() => {
		if (!Array.isArray(Object.values(data)[0])) {
			return [];
		}

		const categories = Object.values(data)[0]?.map((entry) => entry.category);

		return categories.map((entry) =>
			t(`pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.chart.xAxisLabels.${entry}`),
		);
	}, [data, t]);

	useEffect(() => {
		if (width < UiBreakpoints.xs) {
			setChartSize("xs");
		} else if (width < UiBreakpoints.sm) {
			setChartSize("sm");
		} else if (width < UiBreakpoints.md) {
			setChartSize("md");
		} else if (width < UiBreakpoints.lg) {
			setChartSize("lg");
		} else if (width < UiBreakpoints.xl) {
			setChartSize("xl");
		} else {
			setChartSize("2xl");
		}
	}, [width]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setRange([0, 2000]);
				setTitleClassNames("break-words");
				break;

			case "sm":
				setRange([0, 3000]);
				setTitleClassNames("break-all");
				break;

			case "md":
				setRange([0, 4000]);
				setTitleClassNames("break-all");
				break;

			case "lg":
				setRange([0, 4000]);
				setTitleClassNames("break-all");
				break;

			case "xl":
				setRange([0, 4000]);
				setTitleClassNames("break-all");
				break;

			default:
				setRange([0, 4000]);
				setTitleClassNames("break-word");
				break;
		}
	}, [chartSize]);

	return (
		<div>
			<MatrixDiagram
				data={data}
				titles={xAxisLabels}
				Tooltip={AvgMaterialCompositionLargeHomeAppliancesTooltip}
				yAxisTranslationLabelPrefix="pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.chart.yAxisLabels"
				range={range}
				titleClassNames={titleClassNames}
			/>
		</div>
	);
};

export { AvgMaterialCompositionLargeHomeAppliances };
