import { FC } from "react";
import { useTranslation } from "react-i18next";

import { WEEE_FLOW_IN_THE_HOME_APPLIANCE_SECTOR_CHART_DATA_KEYS } from "../WeeeFlowInTheHomeApplianceSector.const";

import { WeeeFlowInTheHomeApplianceSectorLineChartToolTipProps } from "./WeeeFlowInTheHomeApplianceSectorLineChartToolTip.type";

const WeeeFlowInTheHomeApplianceSectorLineChartToolTip: FC<WeeeFlowInTheHomeApplianceSectorLineChartToolTipProps> = (props) => {
	const { t } = useTranslation();

	const { active, payload } = props;

	if (!active || !payload || !payload.length) {
		return null;
	}

	return (
		<div className="p-3 m-0 bg-white border border-primary">
			<p className="font-bold">{props.label}</p>
			<ul>
				{payload.map((data) => {
					const dataKey = WEEE_FLOW_IN_THE_HOME_APPLIANCE_SECTOR_CHART_DATA_KEYS.find((key) => key.name === data?.name);

					return (
						<li key={data?.dataKey} style={{ color: data?.color }}>
							{t(dataKey?.translation || data?.name)}: {data?.value} Mt
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export { WeeeFlowInTheHomeApplianceSectorLineChartToolTip };
