export const getSidebarItemClassName = (props: { isActive: boolean; isPending?: boolean }) => {
	const inActiveClasses = "hover:bg-gray-100 text-gray-900";
	const activeClasses = "bg-blue-100 hover:bg-blue-100 text-blue-800";

	if (typeof props !== "object") {
		return inActiveClasses;
	}

	return props?.isActive ? activeClasses : inActiveClasses;
};
