import { useEffect, useState } from "react";
import { useWindowSize } from "./useWindowSize";
import { UiBreakpoints } from "../config/ui/breakpoints";
import { ChartSize } from "../types/charts/size";

export const useBreakpoint = () => {
	const { width } = useWindowSize();

	const [currentBreakpoint, setCurrentBreakpoint] = useState<ChartSize>();

	useEffect(() => {
		if (width < UiBreakpoints.xs) {
			setCurrentBreakpoint("xs");
		} else if (width < UiBreakpoints.sm) {
			setCurrentBreakpoint("sm");
		} else if (width < UiBreakpoints.md) {
			setCurrentBreakpoint("md");
		} else if (width < UiBreakpoints.lg) {
			setCurrentBreakpoint("lg");
		} else if (width < UiBreakpoints.xl) {
			setCurrentBreakpoint("xl");
		} else {
			setCurrentBreakpoint("2xl");
		}
	}, [width]);

	return currentBreakpoint;
};
