import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/images/content/introduction/products/products-visual-desktop.webp";
import Mobile from "../../../../assets/vectors/content/introduction/products/products-visual-mobile.svg";

const Products: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col">
			<img
				className="hidden md:block"
				src={Desktop}
				alt={t("pages.introduction.products.blocks.visual.alt") as string}
				title={t("pages.introduction.products.blocks.visual.alt") as string}
			/>
			<img
				className="block md:hidden"
				src={Mobile}
				alt={t("pages.introduction.products.blocks.visual.alt") as string}
				title={t("pages.introduction.products.blocks.visual.alt") as string}
			/>
		</div>
	);
};

export { Products };
