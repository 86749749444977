import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Container } from "../../../components/Container/Container";
import { Seo } from "../../../components/Seo/Seo";

export const GeneralErrorPage = () => {
	const { t } = useTranslation();
	const error = useRouteError();

	useEffect(() => {
		console.error(error);
	}, [error]);

	return (
		<Container>
			<Seo title={t("pages.error.general.seoTitle")} description={t("pages.error.general.seoDescription") as string} />

			<h1 className="mb-5">{t("pages.error.general.oops")}</h1>
			<p className="text-base mb-2">{t("pages.error.general.text")}</p>

			{isRouteErrorResponse(error) && (
				<p>
					<i>{error?.statusText || error?.data?.message}</i>
				</p>
			)}
		</Container>
	);
};
