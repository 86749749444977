import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ImageGridItemProps } from "./ImageGridItem.type";

const ImageGridItem: FC<ImageGridItemProps> = ({ children, item }) => {
	const { t } = useTranslation();

	if (typeof item.url === "string") {
		if (item?.isInternalUrl) {
			return (
				<NavLink title={item?.imageAlt ? (t(item?.imageAlt) as string) : ""} to={item.url}>
					{children}
				</NavLink>
			);
		}

		return (
			<a href={item.url} title={item?.imageAlt ? (t(item?.imageAlt) as string) : ""} target="_blank" rel="noreferrer">
				{children}
			</a>
		);
	}

	return <>{children}</>;
};

export { ImageGridItem };
