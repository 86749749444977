import { FC, useMemo } from "react";

import { ReactComponent as AlertTriangle } from "../../assets/vectors/icons/alert-triangle.svg";
import { ReactComponent as CheckCircle } from "../../assets/vectors/icons/check-circle.svg";
import { ReactComponent as Info } from "../../assets/vectors/icons/info.svg";
import { ReactComponent as XOctagon } from "../../assets/vectors/icons/x-octagon.svg";

import { AlertProps } from "./Alert.type";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Alert: FC<AlertProps> = ({ type, title, message, children }) => {
	const { t } = useTranslation();

	const alertContainerClasses = useMemo(() => {
		let classes = "";

		switch (type) {
			case "success":
				classes = "text-green-800 border-green-300 bg-green-50";
				break;

			case "warning":
				classes = "text-yellow-800 border-yellow-300 bg-yellow-50";
				break;

			case "error":
				classes = "text-red-800 border-red-300 bg-red-50";
				break;

			case "info":
			default:
				classes = "text-blue-800 border-blue-300 bg-blue-50";
				break;
		}

		return classes;
	}, [type]);

	const alertTextClasses = useMemo(() => {
		let classes = "";

		switch (type) {
			case "success":
				classes = "text-green-800";
				break;

			case "warning":
				classes = "text-orange-800";
				break;

			case "error":
				classes = "text-red-800";
				break;

			case "info":
			default:
				classes = "text-blue-800";
				break;
		}

		return classes;
	}, [type]);

	return (
		<div className={classNames("p-4 border rounded-lg", alertContainerClasses, alertTextClasses)}>
			<div className="flex items-center gap-2">
				{type === "info" && <Info width={20} aria-hidden />}
				{type === "warning" && <AlertTriangle width={20} aria-hidden />}
				{type === "success" && <CheckCircle width={20} aria-hidden />}
				{type === "error" && <XOctagon width={20} aria-hidden />}
				{title && <span className="sr-only">{t(`general.alert.iconAlt.${type}`)}</span>}
				<h3 className={classNames("text-lg font-medium", alertTextClasses)}>{title ? title : t(`general.alert.iconAlt.${type}`)}</h3>
			</div>
			{message ? <p className={classNames("mt-2 text-sm", alertTextClasses)} dangerouslySetInnerHTML={{ __html: message }} /> : null}
			{children}
		</div>
	);
};

export { Alert };
