import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "../../Alert/Alert";
import {
	ContentBlock as IContentBlock,
	CustomContentBlockData,
	ImageGridContentBlockData,
	TextContentBlockData,
	TextWithImageContentBlockData,
	ImageContentBlockData,
	HeaderContentBlockData,
} from "../ContentBuilder.type";
import { CustomBlock } from "../CustomBlock/CustomBlock";
import { ImageBlock } from "../ImageBlock/ImageBlock";
import { ImageGridBlock } from "../ImageGridBlock/ImageGridBlock";
import { TextBlock } from "../TextBlock/TextBlock";
import { TextWithImageBlock } from "../TextWithImageBlock/TextWithImageBlock";

import { ContentBlockProps } from "./ContentBlock.type";
import { HeaderBlock } from "../HeaderBlock/HeaderBlock";

const contentBlockClassNames = "my-8 md:my-12";

const ContentBlock: FC<ContentBlockProps> = ({ block, id }) => {
	const { t } = useTranslation();

	switch (block.type) {
		case "text":
			return (
				<div className={contentBlockClassNames}>
					<TextBlock id={id} data={block.data as TextContentBlockData} />
				</div>
			);
		case "header":
			return (
				<div className={contentBlockClassNames}>
					<HeaderBlock id={id} data={block.data as HeaderContentBlockData} />
				</div>
			);
		case "text-image":
			return (
				<div className={contentBlockClassNames}>
					<TextWithImageBlock id={id} data={block.data as TextWithImageContentBlockData} />
				</div>
			);
		case "image-grid":
			return (
				<div className={contentBlockClassNames}>
					<ImageGridBlock id={id} data={block.data as ImageGridContentBlockData} />
				</div>
			);

		case "image":
			return (
				<div className={contentBlockClassNames}>
					<ImageBlock id={id} data={block.data as ImageContentBlockData} />
				</div>
			);

		case "alert": {
			const { title, message, ...rest } = block.data;

			return (
				<div className={contentBlockClassNames}>
					<Alert
						{...rest}
						{...(typeof title === "string" && { title: t(title) as string })}
						{...(typeof message === "string" && { message: t(message) as string })}
					/>
				</div>
			);
		}

		case "custom":
			return (
				<div className={contentBlockClassNames}>
					<CustomBlock id={id} data={block.data as CustomContentBlockData} />
				</div>
			);

		default:
			console.error(`Unknown content block type: ${(block as IContentBlock)?.type}`);
			return null;
	}
};

export { ContentBlock };
