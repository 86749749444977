import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./translations/en.json";

const resources = {
	en: {
		translation: en,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		supportedLngs: ["en"],
		fallbackLng: "en",
		compatibilityJSON: "v3",
		resources,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		parseMissingKeyHandler: (key) => {
			return `Missing translation for key: ${key}`;
		},
	});

export default i18n;
