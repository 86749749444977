import { FC } from "react";
import { useTranslation } from "react-i18next";

import { AppliaSecretariatProps } from "./AppliaSecretariat.type";
import { StaffMember } from "./StaffMember/StaffMember";

const AppliaSecretariat: FC<AppliaSecretariatProps> = ({ members }) => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col gap-14">
			<div className="flex flex-col items-center text-center gap-4">
				<h2>{t("pages.introduction.appliaSecretariat.title")}</h2>
				<p className="w-full md:max-w-[65%]">{t("pages.introduction.appliaSecretariat.intro")}</p>
			</div>

			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10">
				{members.map((member) => (
					<StaffMember
						key={member.name}
						name={member.name}
						imageUrl={member.imageUrl}
						background={t(member.background)}
						functionTitle={t(member.functionTitle)}
						toggleText={t("pages.introduction.appliaSecretariat.background")}
					/>
				))}
			</div>
		</div>
	);
};

export { AppliaSecretariat };
