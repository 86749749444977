import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { AppliaPillarNarrative } from "../../config/routes/routes";

import { narrativeTranslationKeys } from "./Narrative.const";
import { NarrativeProps } from "./Narrative.type";

// Can't move this to the const file because Tailwind dynamic class generation will break
const narrativeColorClasses: { [key in AppliaPillarNarrative]: string } = {
	"Market trends": "bg-[#5a3286] text-white",
	"Material flow": "bg-[#c6dbe1] text-[#205a6c]",
	Production: "bg-[#e6cff1] text-[#5a3286]",
	"R&D": "bg-[#205a6c] text-white",
	Recycling: "bg-[#d4ecbb] text-[#0f734b]",
	Repair: "bg-[#bfe1f6] text-[#0a53a8] ",
	"Secondary raw materials": "bg-[#e6e6e6] text-[#3d3d3d]",
	"Use & consumption": "bg-[#ffe59f] text-[#473821]",
};

const Narrative: FC<NarrativeProps> = ({ type }) => {
	const { t } = useTranslation();

	return (
		<span className={classNames("inline-block rounded-full px-4 py-2", `${narrativeColorClasses[type]}`)}>
			{t(narrativeTranslationKeys[type])}
		</span>
	);
};

export { Narrative };
