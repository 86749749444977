import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";

import { routes } from "../../config/routes/routes";
import { Header } from "../navigation/Header/Header";
import { Sidebar } from "../navigation/Sidebar/Sidebar";

import "./Layout.css";

export const Layout = () => {
	// Extracts pathname property(key) from an object
	const { pathname } = useLocation();

	// Automatically scrolls to top whenever pathname changes
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className="bg-white flex flex-col flex-grow main antialiased">
			<Header />
			<div className="grid grid-cols-only-content grid-cols-sidebar-content">
				<div className="hidden lg:block">
					<div className="lg:pt-20 fixed lg:sticky top-0 left-0 bottom-0 right-0 py-0 sidebar z-[5]">
						<div className="sticky top-0 lg:bottom-0 lg:h-[calc(100vh-5rem)] flex flex-col">
							<Sidebar routes={routes} />
						</div>
					</div>
				</div>
				<main className="mt-20 bg-white text-slate-800 overflow-x-hidden">
					<Outlet />
				</main>
			</div>
		</div>
	);
};
