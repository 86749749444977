import { FC, useCallback, useEffect, useState } from "react";

import { LineChart } from "../../../Charts/LineChart/LineChart";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { AverageEnergyConsumptionWashingMachinesProps } from "./AverageEnergyConsumptionWashingMachines.type";
import { AVERAGE_ENERGY_CONSUMPTION_WASHING_MACHINES_DATA_KEYS } from "./AverageEnergyConsumptionWashingMachines.const";

const AverageEnergyConsumptionWashingMachines: FC<AverageEnergyConsumptionWashingMachinesProps> = ({ data }) => {
	const chartSize = useBreakpoint();

	const [dotLabelFontSize, setDotLabelFontSize] = useState<number>(12);

	const [chartData, setChartData] = useState(
		data.map((value) => {
			return { time: value.time, average: value.average };
		}),
	);
	const [axisFontSize, setAxisFontSize] = useState(12);
	const [marginLeft, setMarginLeft] = useState(0);
	const [xAxisPadding, setXAxisPadding] = useState(50);
	const [chartHeight, setChartHeight] = useState(450);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setChartData(data.filter((dataEntry) => ["1998","2002", "2006", "2010", "2014", "2018", "2022"].includes(`${dataEntry.time}`)));
				setMarginLeft(-15);
				setAxisFontSize(9);
				setDotLabelFontSize(9);
				setXAxisPadding(20);
				setChartHeight(250);
				break;

			case "sm":
				setChartData(
					data.filter((dataEntry) => ["1998","2001", "2004", "2007", "2010", "2013", "2016", "2019", "2022"].includes(`${dataEntry.time}`)),
				);
				setMarginLeft(-5);
				setAxisFontSize(11);
				setDotLabelFontSize(11);
				setXAxisPadding(15);
				setChartHeight(350);
				break;

			case "md":
				setChartData(
					data.filter((dataEntry) => ["1998","2000", "2002", "2004", "2006", "2008", "2010", "2012", "2014", "2016", "2018", "2020", "2022"].includes(`${dataEntry.time}`)),
				);
				setMarginLeft(-5);
				setAxisFontSize(12);
				setDotLabelFontSize(12);
				setXAxisPadding(20);
				setChartHeight(350);
				break;

			case "lg":
				setChartData(
					data.filter((dataEntry) => ["1998","2000", "2002", "2004", "2006", "2008", "2010", "2012", "2014", "2016", "2018", "2020", "2022"].includes(`${dataEntry.time}`)),
				);
				setMarginLeft(0);
				setAxisFontSize(14);
				setDotLabelFontSize(11);
				setXAxisPadding(35);
				setChartHeight(450);
				break;

			case "xl":
				setMarginLeft(0);
				setChartData(
					data.filter((dataEntry) => ["1998","2000", "2002", "2004", "2006", "2008", "2010", "2012", "2014", "2016", "2018", "2020", "2022"].includes(`${dataEntry.time}`)),
				);
				setAxisFontSize(12);
				setDotLabelFontSize(11);
				setXAxisPadding(35);
				setChartHeight(450);
				break;

			case "2xl":
			default:
				setMarginLeft(0);
				setChartData(data);
				setAxisFontSize(14);
				setDotLabelFontSize(14);
				setXAxisPadding(35);
				setChartHeight(450);
				break;
		}
	}, [chartSize, data]);

	const formatNumber = useCallback((number: number) => {
		return `${new Intl.NumberFormat("en").format(+number.toFixed(2))}`;
	}, []);

	return (
		<div className="flex flex-col gap-8">

			<LineChart
				YAxisLabel="Average energy consumption of washing machines (kWh/cycle)"
				data={chartData}
				height={chartHeight}
				marginLeft={marginLeft}
				labelProps={{ fontSize: dotLabelFontSize, formatter: formatNumber }}
				XAxisProps={{
					dataKey: "time",
					padding: { left: xAxisPadding, right: xAxisPadding },
					tick: { fontSize: axisFontSize },
				}}
				YAxisProps={{
					tickCount: 5,
					tick: { fontSize: axisFontSize },
					tickFormatter: formatNumber,
					domain: [0, 2],
					interval: 0,
				}}
				dataKeys={AVERAGE_ENERGY_CONSUMPTION_WASHING_MACHINES_DATA_KEYS}
				showToolTip={false}
			/>
		</div>
	);
};

export { AverageEnergyConsumptionWashingMachines };
