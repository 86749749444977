/* BG IMAGES */
import BgAcceleratingEuropesGrowth from "../../assets/images/content/pillar-2/index/bg-header.webp";
import BgAdvancingSustainableLifestyles from "../../assets/images/content/pillar-1/index/bg-header.webp";
import BgAppliaSecretariat from "../../assets/images/content/introduction/applia-secretariat/bg-header.webp";
import BgDirectMembers from "../../assets/images/content/introduction/direct-members/bg-header.webp";
import BgEuropeApplianceIndustryIn2023 from "../../assets/images/content/introduction/home-appliance-industry-in-europe-in-2023/bg-header.webp";
import BgForeword from "../../assets/images/content/introduction/foreword/bg-header.webp";
import BgNationalAssociations from "../../assets/images/content/introduction/national-associations/bg-header.webp";
import BgPolicyDevelopments from "../../assets/images/content/introduction/policy-developments-in-2023/bg-header.webp";
import BgProducts from "../../assets/images/content/introduction/products/bg-header.webp";
import BgWhatToExpect from "../../assets/images/content/introduction/what-to-expect-in-2023/bg-header.webp";

/* OTHER IMAGES */
import AverageMaterialCompositionOfHomeAppliancesVisual from "../../assets/images/content/pillar-1/average-material-composition-of-home-appliances/average-material-composition-of-home-appliances-visual.webp";
import ConnectedHome from "../../assets/images/content/introduction/policy-developments-in-2023/connectedhome.webp";
import Consumers from "../../assets/images/content/introduction/policy-developments-in-2023/consumers.webp";
import CriticalRawMaterials from "../../assets/images/content/introduction/policy-developments-in-2023/criticalrawmaterials.webp";
import Deforestation from "../../assets/images/content/introduction/policy-developments-in-2023/deforestation.webp";
import DiscardedAppliances from "../../assets/images/content/introduction/policy-developments-in-2023/discardedappliances.webp";
import EnergyPerfomanceOfBuildings from "../../assets/images/content/introduction/policy-developments-in-2023/energyperformanceofbuildings.webp";
import GreenClaims from "../../assets/images/content/introduction/policy-developments-in-2023/greenclaims.webp";
import HakanBulgurLu from "../../assets/images/content/introduction/foreword/hakan_bulgurlu.jpg";
import HoardingOfWeeeVisual from "../../assets/vectors/content/pillar-1/hoarding-of-weee/Hoarding of WEEE.svg";
import PaoloFalcioni from "../../assets/images/content/introduction/foreword/paolo_falcioni.jpg";
import Project1 from "../../assets/images/content/introduction/projects/circular-plastics-alliance.webp";
import Project2 from "../../assets/images/content/introduction/projects/ecosweee.webp";
import Project3 from "../../assets/images/content/introduction/projects/i4r.webp";
import Project4 from "../../assets/images/content/introduction/projects/increace.webp";
import Project5 from "../../assets/images/content/introduction/projects/primus.webp";
import Project6 from "../../assets/images/content/introduction/projects/futuram.webp";
import QuantitiesOfIndividualMaterialsUsedInHomeAppliances from "../../assets/images/content/pillar-1/quantities-of-individual-materials-used-in-home-appliances/quantities-of-materials-used.webp";
import RepairPillar1 from "../../assets/images/content/pillar-1/index/repair.webp";
import Sustainability from "../../assets/images/content/introduction/policy-developments-in-2023/sustainability.webp";
import Sustainability2 from "../../assets/images/content/pillar-2/index/sustainability2.webp";
import Trade from "../../assets/images/content/introduction/policy-developments-in-2023/trade.webp";
import TradePillar2 from "../../assets/images/content/pillar-2/index/trade.webp";

/* COMPONENT IMPORTS */
import { ContentBlock } from "../../components/ContentBuilder/ContentBuilder.type";

// Introduction custom components
import { AppliaSecretariat } from "../../components/CustomPageBlocks/introduction/AppliaSecretariat/AppliaSecretariat";
import { NationalAssociations } from "../../components/CustomPageBlocks/introduction/NationalAssociations/NationalAssociations";
import { Roadmap } from "../../components/CustomPageBlocks/introduction/Roadmap/Roadmap";
import { RoadmapButton } from "../../components/CustomPageBlocks/introduction/Roadmap/RoadmapButton";;

// Introduction data files
import { AppliaSecretariatMembers } from "../../assets/data/content/introduction/applia-secretariat/data";
import { directMembers } from "../../assets/data/content/introduction/direct-members/data";

// Pillar 1 custom components
import { AverageEnergyConsumptionDishwashers } from "../../components/CustomPageBlocks/pillar1/AverageEnergyConsumption/AverageEnergyConsumptionDishwashers";
import { AverageEnergyConsumptionWashingMachines } from "../../components/CustomPageBlocks/pillar1/AverageEnergyConsumption/AverageEnergyConsumptionWashingMachines";
import { AverageWaterConsumptionDishwashers } from "../../components/CustomPageBlocks/pillar1/AverageWaterConsumption/AverageWaterConsumptionDishwashers";
import { AverageWaterConsumptionWashingMachines } from "../../components/CustomPageBlocks/pillar1/AverageWaterConsumption/AverageWaterConsumptionWashingMachines";
import { AvgMaterialCompositionLargeHomeAppliances } from "../../components/CustomPageBlocks/pillar1/AvgMaterialCompositionLargeHomeAppliances/AvgMaterialCompositionLargeHomeAppliances";
import { AvgMaterialCompositionSmallHomeAppliances } from "../../components/CustomPageBlocks/pillar1/AvgMaterialCompositionSmallHomeAppliances/AvgMaterialCompositionSmallHomeAppliances";
import { EnergyConsumptionInTheHomeIn2021 } from "../../components/CustomPageBlocks/pillar1/EnergyConsumptionInTheHomeIn2021/EnergyConsumptionInTheHomeIn2021";
import { HomeApplianceIndustryInEuropeIn2023 } from "../../components/CustomPageBlocks/introduction/HomeApplianceIndustryInEuropeIn2023/HomeApplianceIndustryInEuropeIn2023";
import { MaterialsRecoveredFromWaste } from "../../components/CustomPageBlocks/pillar1/MaterialsRecoveredFromWaste/MaterialsRecoveredFromWaste";
import { MaterialUseDuringTheProductionProcess } from "../../components/CustomPageBlocks/pillar1/MaterialUseDuringTheProductionProcess/MaterialUseDuringTheProductionProcess";
import { PlasticFlowsFromHomeAppliances } from "../../components/CustomPageBlocks/pillar1/PlasticFlowsFromHomeAppliances/PlasticFlowsFromHomeAppliances";
import { Products } from "../../components/CustomPageBlocks/introduction/Products/Products";
import { RoutesOfRecycledPlastic } from "../../components/CustomPageBlocks/pillar1/RoutesOfRecycledPlastic/RoutesOfRecycledPlastic";
import { WeeeFlowInTheHomeApplianceSector } from "../../components/CustomPageBlocks/pillar1/WeeeFlowInTheHomeApplianceSector/WeeeFlowInTheHomeApplianceSector";

// Pillar 1 data files
import AverageEnergyConsumptionDishwashersData from "../../assets/data/content/pillar-1/average-energy-consumption-dishwashers/data.json";
import AverageEnergyConsumptionWashingMachinesData from "../../assets/data/content/pillar-1/average-energy-consumption-washing-machines/data.json";
import AverageWaterConsumptionDishwashersData from "../../assets/data/content/pillar-1/average-water-consumption-dishwashers/data.json";
import AverageWaterConsumptionWashingMachinesData from "../../assets/data/content/pillar-1/average-water-consumption-washing-machines/data.json";
import AvgMaterialCompositionLargeHomeAppliancesData from "../../assets/data/content/pillar-1/avg-material-composition-large-home-appliances/data.json";
import AvgMaterialCompositionSmallHomeAppliancesData from "../../assets/data/content/pillar-1/avg-material-composition-small-home-appliances/data.json";
import MaterialsRecoveredFromWasteData from "../../assets/data/content/pillar-1/materials-recovered-from-waste/data.json";
import WeeeFlowInTheHomeApplianceSectorLineChartData from "../../assets/data/content/pillar-1/weee-flow-in-the-home-appliance-sector/line-chart-data.json";

// Pillar 2 custom components
import { DirectMembersManufacturingSitesInEurope } from "../../components/CustomPageBlocks/pillar2/DirectMembersManufacturingSitesInEurope/DirectMembersManufacturingSitesInEurope";
import { EeePlacedOnTheEuMarket } from "../../components/CustomPageBlocks/pillar2/EeePlacedOnTheEuMarket/EeePlacedOnTheEuMarket";
import { EuropeanMarketHistoricalPicture } from "../../components/CustomPageBlocks/pillar2/EuropeanMarketHistoricalPicture/EuropeanMarketHistoricalPicture";
import { EvolutionOfImportAndExportOfHomeAppliancesInEurope } from "../../components/CustomPageBlocks/pillar2/EvolutionOfImportAndExportOfHomeAppliancesInEurope/EvolutionOfImportAndExportOfHomeAppliancesInEurope";
import { Top10ImportAndExportCountries } from "../../components/CustomPageBlocks/pillar2/Top10ImportAndExportCountries/Top10ImportAndExportCountries";
import { ImportAndExportOfHomeAppliancesInEuropeByContinent } from "../../components/CustomPageBlocks/pillar2/ImportAndExportOfHomeAppliancesInEuropeByContinent/ImportAndExportOfHomeAppliancesInEuropeByContinent";
import { InvestmentInRAndDByEuMemberStates } from "../../components/CustomPageBlocks/pillar2/InvestmentInRAndDByEuMemberStates/InvestmentInRAndDByEuMemberStates";
import { InvestmentRDvsGDP } from "../../components/CustomPageBlocks/pillar2/InvestmentRDvsGDP/InvestmentRDvsGDP";
import { LargeAppliancesSoldGlobally } from "../../components/CustomPageBlocks/pillar2/LargeAppliancesSoldGlobally/LargeAppliancesSoldGlobally";
import { PatentsFiledAndGrantedForHomeAppliances } from "../../components/CustomPageBlocks/pillar2/PatentsFiledAndGrantedForHomeAppliances/PatentsFiledAndGrantedForHomeAppliances";
import { UnitsTradedInEurope } from "../../components/CustomPageBlocks/pillar2/UnitsTradedInEurope/UnitsTradedInEurope";

// Pillar 2 data files
import CountryTradeObjects from "../../assets/data/content/pillar-2/shared/CountryTradeData.json";
import DirectMembersManufacturingSitesInEuropeData from "../../assets/data/content/pillar-2/direct-members-manufacturing-sites-in-europe/data.json";
import EeePlacedOnTheEuMarketData from "../../assets/data/content/pillar-2/eee-placed-on-the-eu-market/data.json";
import EuropeanMarketHistoricalPictureData from "../../assets/data/content/pillar-2/european-market-historical-picture/data.json";
import EUTradeObjects from "../../assets/data/content/pillar-2/shared/EUTradeData.json";
import InvestmentRDvsGDPData from "../../assets/data/content/pillar-2/investment-rd-vs-gdp/data.json";
import PatentsFiledAndGrantedForHomeAppliancesData from "../../assets/data/content/pillar-2/patents-filed-and-granted-for-home-appliances/data.json";

export interface PageContentSource {
	label: string;
	url?: string;
}

export interface PagePdf {
	path: string;
	filename?: string;
}

export interface PageContent {
	heroIntro?: string;
	heroImage?: string;
	pageIntro?: string;
	blocks: ContentBlock[];
	source?: PageContentSource | Array<Required<PageContentSource>>;
	pdf?: PagePdf;
}

export const pageContent: { [key: string]: PageContent } = {
	"navigation.introduction.foreword": {
		heroImage: BgForeword,
		heroIntro: "pages.introduction.foreword.heroIntro",
		blocks: [
			{
				type: "text-image",
				id: "foreword-hakan-bulgurlu",
				data: {
					text: ["pages.introduction.foreword.blocks.hakanBulgurlu.paragraph1", "pages.introduction.foreword.blocks.hakanBulgurlu.paragraph2"],
					title: "pages.introduction.foreword.blocks.hakanBulgurlu.title",
					type: "image-left",
					imageAlt: "pages.introduction.foreword.blocks.hakanBulgurlu.imageAlt",
					imageUrl: HakanBulgurLu,
					imageSize: "normal",
				},
			},
			{
				type: "text-image",
				id: "foreword-paolo-falcioni",
				data: {
					text: ["pages.introduction.foreword.blocks.paoloFalcioni.paragraph1", "pages.introduction.foreword.blocks.paoloFalcioni.paragraph2", "pages.introduction.foreword.blocks.paoloFalcioni.paragraph3"],
					title: "pages.introduction.foreword.blocks.paoloFalcioni.title",
					type: "image-left",
					imageAlt: "pages.introduction.foreword.blocks.paoloFalcioni.imageAlt",
					imageUrl: PaoloFalcioni,
					imageSize: "normal",
				},
			},
		],
	},
	"navigation.introduction.appliaDirectMembers": {
		heroImage: BgDirectMembers,
		heroIntro: "pages.introduction.appliaDirectMembers.heroIntro",
		blocks: [
			{
				id: "applia-direct-members-intro",
				type: "text",
				data: {
					text: [
						"pages.introduction.appliaDirectMembers.blocks.intro.paragraph1",
						"pages.introduction.appliaDirectMembers.blocks.intro.paragraph2",
					],
				},
			},
			{
				id: "applia-direct-members-grid",
				type: "image-grid",
				data: {
					cols: 2,
					responsiveCols: {
						xs: 3,
						md: 4,
					},
					items: directMembers,
				},
			},
		],
	},
	"navigation.introduction.nationalAssociations": {
		heroImage: BgNationalAssociations,
		heroIntro: "pages.introduction.nationalAssociations.heroIntro",
		blocks: [
			{
				type: "custom",
				id: "pages.introduction.nationalAssociations.map",
				data: {
					component: NationalAssociations,
				},
			},
		],
	},
	"navigation.introduction.appliaSecretariat": {
		heroImage: BgAppliaSecretariat,
		blocks: [
			{
				id: "navigation-introduction-appliaSecretariat-custom",
				type: "custom",
				data: {
					component: AppliaSecretariat,
					props: {
						members: AppliaSecretariatMembers,
					},
				},
			},
		],
	},
	"navigation.introduction.europeHomeApplianceIndustryIn2023": {
		heroImage: BgEuropeApplianceIndustryIn2023,
		blocks: [
			{
				id: "pages.introduction.europeHomeApplianceIndustryIn2022.blocks.data",
				type: "custom",
				data: {
					component: HomeApplianceIndustryInEuropeIn2023,
				},
			},
		],
	},
	"navigation.introduction.products": {
		heroIntro: "pages.introduction.products.pageIntro",
		heroImage: BgProducts,
		blocks: [
			{
				type: "custom",
				id: "pages.introduction.products.blocks.visual",
				data: {
					component: Products,
				},
			},
		],
	},
	"navigation.introduction.projects": {
		heroImage: BgProducts,
		blocks: [
			{
				type: "text-image",
				id: "project1",
				data: {
					text: ["pages.introduction.projects.blocks.project1.paragraph"],
					type: "image-special",
					imageAlt: "pages.introduction.projects.blocks.project1.imageAlt",
					imageUrl: Project1,
					imageSize: "small",
				},
			},
			{
				type: "text-image",
				id: "project2",
				data: {
					text: ["pages.introduction.projects.blocks.project2.paragraph"],
					type: "image-special",
					imageAlt: "pages.introduction.projects.blocks.project1.imageAlt",
					imageUrl: Project2,
					imageSize: "small",
				},
			},
			{
				type: "text-image",
				id: "project3",
				data: {
					text: ["pages.introduction.projects.blocks.project3.paragraph"],
					type: "image-special",
					imageAlt: "pages.introduction.projects.blocks.project1.imageAlt",
					imageUrl: Project3,
					imageSize: "small",
				},
			},
			{
				type: "text-image",
				id: "project4",
				data: {
					text: ["pages.introduction.projects.blocks.project4.paragraph"],
					type: "image-special",
					imageAlt: "pages.introduction.projects.blocks.project1.imageAlt",
					imageUrl: Project4,
					imageSize: "small",
				},
			},
			{
				type: "text-image",
				id: "project5",
				data: {
					text: ["pages.introduction.projects.blocks.project5.paragraph"],
					type: "image-special",
					imageAlt: "pages.introduction.projects.blocks.project1.imageAlt",
					imageUrl: Project5,
					imageSize: "small",
				},
			},
			{
				type: "text-image",
				id: "project6",
				data: {
					text: ["pages.introduction.projects.blocks.project6.paragraph"],
					type: "image-special",
					imageAlt: "pages.introduction.projects.blocks.project1.imageAlt",
					imageUrl: Project6,
					imageSize: "small",
				},
			},
		],
	},
	"navigation.introduction.policyDevelopmentsIn2023": {
		heroImage: BgPolicyDevelopments,
		blocks: [
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.introduction",
				type: "text",
				data: {
					text: ["pages.introduction.policyDevelopmentsIn2023.blocks.introduction.paragraph1", "pages.introduction.policyDevelopmentsIn2023.blocks.introduction.paragraph2"],
				}
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability-text-image",
				type: "text-image",
				data: {
					imageSize: "normal",
					type: "image-left",
					imageUrl: Sustainability,
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.espr.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.espr.paragraph"
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.weee-text-image",
				type: "text-image",
				data: {
					imageSize: "normal",
					type: "image-right",
					imageUrl: DiscardedAppliances,
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.weee.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.weee.paragraph"
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.fgas",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.fgas.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.fgas.paragraph",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greendeal",
				type: "text-image",
				data: {
					imageSize: "normal",
					type: "image-left",
					imageUrl: CriticalRawMaterials,
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greendeal.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greendeal.paragraph1",
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greendeal.paragraph2",
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greendeal.paragraph3",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greenclaims",
				type: "text-image",
				data: {
					imageSize: "normal",
					type: "image-right",
					imageUrl: GreenClaims,
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greenclaims.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.greenclaims.paragraph"
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.ppwr",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.ppwr.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.sustainability.ppwr.paragraph"
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.rightToRepair",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.rightToRepair.title",
					text: ["pages.introduction.policyDevelopmentsIn2023.blocks.corporate.rightToRepair.paragraph1","pages.introduction.policyDevelopmentsIn2023.blocks.corporate.rightToRepair.paragraph2","pages.introduction.policyDevelopmentsIn2023.blocks.corporate.rightToRepair.paragraph3"],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.empowering",
				type: "text-image",
				data: {
					imageUrl: Consumers,
					imageSize: "normal",
					type: "image-left",
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.empowering.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.corporate.empowering.paragraph1",
						"pages.introduction.policyDevelopmentsIn2023.blocks.corporate.empowering.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.due-diligence",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.due-diligence.title",
					text: ["pages.introduction.policyDevelopmentsIn2023.blocks.corporate.due-diligence.paragraph1","pages.introduction.policyDevelopmentsIn2023.blocks.corporate.due-diligence.paragraph2"],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.deforestation",
				type: "text-image",
				data: {
					imageUrl: Deforestation,
					imageSize: "normal",
					type: "image-right",
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.deforestation.title",
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.corporate.deforestation.paragraph"
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.energy",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.energy.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.energy.efficiency",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.energy.efficiency.title",
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.energy.efficiency.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.energy.buildings",
				type: "text-image",
				data: {
					imageSize: "normal",
					imageUrl: EnergyPerfomanceOfBuildings,
					type: "image-left",
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.energy.buildings.title",
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.energy.buildings.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade",
				type: "header",
				data: {
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.title",
					type: "h2",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.ai-act",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.ai-act.title",
					text: ["pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.ai-act.paragraph1","pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.ai-act.paragraph2"],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.cbam-text-image",
				type: "text-image",
				data: {
					imageSize: "normal",
					imageUrl: Trade,
					type: "image-right",
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.cbam.title",
					text: [
						"pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.cbam.paragraph1",
						"pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.cbam.paragraph2",
					],
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.cyber-resilience",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.cyber-resilience.title",
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.cyber-resilience.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.eu-data-act",
				type: "text-image",
				data: {
					imageSize: "normal",
					imageUrl: ConnectedHome,
					type: "image-left",
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.eu-data-act.title",
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.eu-data-act.paragraph",
				},
			},
			{
				id: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.liability",
				type: "text",
				data: {
					title: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.liability.title",
					text: "pages.introduction.policyDevelopmentsIn2023.blocks.digitalisation-trade.liability.paragraph",
				},
			}
		],
	},
	"navigation.introduction.roadmapToAFairSustainableAndProsperousEuropeanSociety": {
		heroImage: BgWhatToExpect,
		pageIntro: "pages.introduction.roadmapToAFairSustainableAndProsperousEuropeanSociety.pageIntro",
		blocks: [
			{
				type: "custom",
				id: "pages.introduction.roadmapToAFairSustainableAndProsperousEuropeanSociety.blocks.visual",
				data: {
					component: Roadmap,
				},
			},
			{
				type: "custom",
				id: "pages.introduction.roadmapToAFairSustainableAndProsperousEuropeanSociety.blocks.button",
				data: {
					component: RoadmapButton,
				},
			},
		]
	},
	"navigation.introduction.headingTowardsThe2024EuropeanElections": {
		heroImage: BgWhatToExpect,
		blocks: [
			{
				id: "pages.introduction.headingTowardsThe2024EuropeanElections.blocks.intro",
				type: "text",
				data: {
					text: ["pages.introduction.headingTowardsThe2024EuropeanElections.blocks.intro.paragraph1","pages.introduction.headingTowardsThe2024EuropeanElections.blocks.intro.paragraph2","pages.introduction.headingTowardsThe2024EuropeanElections.blocks.intro.paragraph3","pages.introduction.headingTowardsThe2024EuropeanElections.blocks.intro.paragraph4"],
				},
			},
		],
	},
	/* 📝 PILLAR 1 */
	"navigation.pillar1.index": {
		heroImage: BgAdvancingSustainableLifestyles,
		heroIntro: "pages.pillar.pillar1.index.heroIntro",
		pageIntro: "pages.pillar.pillar1.index.pageIntro",
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.index.blocks.intro",
				data: {
					text: [
						"pages.pillar.pillar1.index.blocks.intro.paragraph1",
						"pages.pillar.pillar1.index.blocks.intro.paragraph2",
						"pages.pillar.pillar1.index.blocks.intro.paragraph3",
					],
				},
			},
			{
				id: "pages.pillar.pillar1.index.blocks.repair-image",
				type: "text-image",
				data: {
					text: ["pages.pillar.pillar1.index.blocks.repair-image.paragraph1", "pages.pillar.pillar1.index.blocks.repair-image.paragraph2"],
					type: "image-left",
					imageSize: "normal",
					imageUrl: RepairPillar1,
				},
			},
			{
				id: "pages.pillar.pillar1.index.blocks.repair",
				type: "text",
				data: {
					text: ["pages.pillar.pillar1.index.blocks.repair.paragraph1"],
				},
			},
		],
	},
	"navigation.pillar1.materialUseDuringTheProductionProcess": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-MaterialUseDuringTheProductionProcess.pdf",
			filename: "APPLiA - Material use during the production process",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.intro",
				type: "text",
				data: {
					text: "pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.materialUseDuringTheProductionProcess.blocks.visual",
				data: {
					component: MaterialUseDuringTheProductionProcess,
				},
			},
		],
	},
	"navigation.pillar1.averageWaterConsumption": {
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageWaterConsumptionOfWashingMachinesAndDishwashers.pdf",
			filename: "APPLiA - Average water consumption of washing machines and dishwashers",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.averageWaterConsumption.blocks.intro1",
				type: "text",
				data: {
					text: [
						"pages.pillar.pillar1.averageWaterConsumption.blocks.intro1.paragraph1",
						"pages.pillar.pillar1.averageWaterConsumption.blocks.intro1.paragraph2"
				],
				},
			},
			{
				id: "pages.pillar.pillar1.averageWaterConsumption.blocks.chart-washing-machines",
				type: "custom",
				data: {
					component: AverageWaterConsumptionWashingMachines,
					props: {
						data: AverageWaterConsumptionWashingMachinesData,
					},
				},
			},
			{
				id: "pages.pillar.pillar1.averageWaterConsumption.blocks.intro2",
				type: "text",
				data: {
					text: [
						"pages.pillar.pillar1.averageWaterConsumption.blocks.intro2.paragraph1",
						"pages.pillar.pillar1.averageWaterConsumption.blocks.intro2.paragraph2"
				],
				},
			},
			{
				id: "pages.pillar.pillar1.averageWaterConsumption.blocks.chart-dishwashers",
				type: "custom",
				data: {
					component: AverageWaterConsumptionDishwashers,
					props: {
						data: AverageWaterConsumptionDishwashersData,
					},
				},
			},
		],
	},
	"navigation.pillar1.averageEnergyConsumption": {
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageEnergyConsumptionOfWashingMachinesAndDishwashers.pdf",
			filename: "APPLiA - Average energy consumption of washing machines and dishwashers",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.averageEnergyConsumption.blocks.intro1",
				type: "text",
				data: {
					text: [
						"pages.pillar.pillar1.averageEnergyConsumption.blocks.intro1.paragraph1",
						"pages.pillar.pillar1.averageEnergyConsumption.blocks.intro1.paragraph2"
				],
				},
			},
			{
				id: "pages.pillar.pillar1.averageEnergyConsumption.blocks.chart-washing-machines",
				type: "custom",
				data: {
					component: AverageEnergyConsumptionWashingMachines,
					props: {
						data: AverageEnergyConsumptionWashingMachinesData,
					},
				},
			},
			{
				id: "pages.pillar.pillar1.averageEnergyConsumption.blocks.intro2",
				type: "text",
				data: {
					text: [
						"pages.pillar.pillar1.averageEnergyConsumption.blocks.intro2.paragraph1",
						"pages.pillar.pillar1.averageEnergyConsumption.blocks.intro2.paragraph2"
				],
				},
			},
			{
				id: "pages.pillar.pillar1.averageEnergyConsumption.blocks.chart-dishwashers",
				type: "custom",
				data: {
					component: AverageEnergyConsumptionDishwashers,
					props: {
						data: AverageEnergyConsumptionDishwashersData,
					},
				},
			},
		],
	},
	"navigation.pillar1.averageMaterialCompositionOfHomeAppliances": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageMaterialCompositionOfHomeAppliances.pdf",
			filename: "APPLiA - Average material composistion of home appliances",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.intro",
				type: "text",
				data: {
					text: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.intro.paragraph1",
				},
			},
			{
				type: "image",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.visual",
				data: {
					imageUrl: AverageMaterialCompositionOfHomeAppliancesVisual,
					imageAlt: "pages.pillar.pillar1.averageMaterialCompositionOfHomeAppliances.blocks.visual.alt",
					imageSize: "normal",
					imageAlignment: "center",
				},
			},
		],
	},
	"navigation.pillar1.quantitiesOfIndividualMaterialsUsedInHomeAppliances": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-QuantitiesOfIndividualMaterialsUsedInHomeAppliances.pdf",
			filename: "APPLiA - Quantities of individual materials used in home appliances",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.quantitiesOfIndividualMaterialsUsedInHomeAppliances.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.quantitiesOfIndividualMaterialsUsedInHomeAppliances.blocks.intro.paragraph"
				},
			},
			{
				type: "image",
				id: "pages.pillar.pillar1.quantitiesOfIndividualMaterialsUsedInHomeAppliances.blocks.visual",
				data: {
					imageUrl: QuantitiesOfIndividualMaterialsUsedInHomeAppliances,
					imageAlt: "pages.pillar.pillar1.quantitiesOfIndividualMaterialsUsedInHomeAppliances.blocks.visual.alt",
					imageSize: "normal",
					imageAlignment: "center",
				},
			},
		],
	},
	"navigation.pillar1.averageMaterialCompositionOfSmallHomeAppliances": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageMaterialCompositionOfSmallHomeAppliances.pdf",
			filename: "APPLiA - Average material composistion of small home appliances",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.blocks.intro",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfSmallHomeAppliances.chart",
				data: {
					component: AvgMaterialCompositionSmallHomeAppliances,
					props: {
						data: AvgMaterialCompositionSmallHomeAppliancesData,
					},
				},
			},
		],
	},
	"navigation.pillar1.averageMaterialCompositionOfLargeHomeAppliances": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-AverageMaterialCompositionOfLargeHomeAppliances.pdf",
			filename: "APPLiA - Average material composistion of large home appliances",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances.blocks.intro",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.averageMaterialCompositionOfLargeHomeAppliances",
				data: {
					component: AvgMaterialCompositionLargeHomeAppliances,
					props: {
						data: AvgMaterialCompositionLargeHomeAppliancesData,
					},
				},
			},
		],
	},
	"navigation.pillar1.plasticFlowsFromHomeAppliances": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-PlasticFlowsFromHomeAppliances.pdf",
			filename: "APPLiA - Plastic flows from home appliances",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar1.plasticFlowsFromHomeAppliances.blocks.visual",
				data: {
					component: PlasticFlowsFromHomeAppliances,
				},
			},
			{
				id: "pages.pillar.pillar1.plasticFlowsFromHomeAppliances.blocks.alert",
				type: "alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar1.plasticFlowsFromHomeAppliances.blocks.alert.message",
				},
			},
		],
	},
	"navigation.pillar1.routesOfRecycledPlastic": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-RoutesOfRecycledPlastic.pdf",
			filename: "APPLiA - Routes of recycled plastic",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.routesOfRecycledPlastic.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.routesOfRecycledPlastic.blocks.intro.paragraph",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.routesOfRecycledPlastic.blocks.visual",
				data: {
					component: RoutesOfRecycledPlastic,
				},
			},
		],
	},
	"navigation.pillar1.energyConsumptionInTheHomeIn2021": {
		source: {
			label: "Eurostat",
			url: "https://ec.europa.eu/eurostat/databrowser/view/nrg_d_hhq/default/table?lang=en",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-EnergyConsumptionInTheHomeIn2021.pdf",
			filename: "APPLiA - Energy consumption in the home in 2021",
		},
		blocks: [
			{
				id: "pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.intro",
				type: "text",
				data: {
					text: "pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.energyConsumptionInTheHomeIn2021.blocks.visual",
				data: {
					component: EnergyConsumptionInTheHomeIn2021,
				},
			},
		],
	},
	"navigation.pillar1.hoardingOfWeee": {
		source: {
			label: "WEEE Forum data, dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-HoardingOfWEEE.pdf",
			filename: "APPLiA - Hoarding of WEEE",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.hoardingOfWeee.blocks.intro",
				data: {
					text: "pages.pillar.pillar1.hoardingOfWeee.blocks.intro.paragraph1",
				},
			},
			{
				type: "image",
				id: "pages.pillar.pillar1.hoardingOfWeee.blocks.visual",
				data: {
					imageSize: "big",
					imageAlignment: "center",
					imageUrl: HoardingOfWeeeVisual,
					imageAlt: "pages.pillar.pillar1.hoardingOfWeee.blocks.visual.alt",
				},
			},
			{
				type: "alert",
				id: "pages.pillar.pillar1.hoardingOfWeee.blocks.alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar1.hoardingOfWeee.blocks.alert.message",
				},
			},
		],
	},
	"navigation.pillar1.weeeFlowInTheHomeApplianceSector": {
		source: {
			label:
				"dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-WEEEFlowInTheHomeApplianceSector.pdf",
			filename: "APPLiA - WEEEE flow in the home appliances sector",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.intro",
				data: {
					text: ["pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.intro.paragraph1","pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.intro.paragraph2"],
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.weeeFlowInTheHomeApplianceSector.blocks.visual",
				data: {
					component: WeeeFlowInTheHomeApplianceSector,
					props: {
						lineChartData: WeeeFlowInTheHomeApplianceSectorLineChartData
					},
				},
			},
		],
	},
	"navigation.pillar1.materialsRecoveredFromWaste": {
		source: {
			label: "dss+",
		},
		pdf: {
			path: "/files/pdf/pillar-1/APPLiA-MaterialsRecoveredFromWaste.pdf",
			filename: "APPLiA - Materials recovered from waste",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.intro",
				data: {
					text: [
						"pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.intro.paragraph1",
						"pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.intro.paragraph2",
						"pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.intro.paragraph3"
					]
				}
			},
			{
				type: "custom",
				id: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.visual",
				data: {
					component: MaterialsRecoveredFromWaste,
					props: {
						data: MaterialsRecoveredFromWasteData,
					},
				},
			},
			{
				type: "alert",
				id: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.alert",
				data: {
					type: "info",
					message: "pages.pillar.pillar1.materialsRecoveredFromWaste.blocks.alert.message",
				},
			},
		],
	},
	/* 📝 PILLAR 2 */
	"navigation.pillar2.index": {
		heroImage: BgAcceleratingEuropesGrowth,
		heroIntro: "pages.pillar.pillar2.index.heroIntro",
		pageIntro: "pages.pillar.pillar2.index.pageIntro",
		blocks: [
			{
				id: "pages.pillar.pillar2.index.blocks.eu-single-market-image",
				type: "text-image",
				data: {
					text: [
						"pages.pillar.pillar2.index.blocks.eu-single-market-image.paragraph1",
						"pages.pillar.pillar2.index.blocks.eu-single-market-image.paragraph2",
					],
					imageSize: "normal",
					type: "image-left",
					imageUrl: TradePillar2,
				},
			},
			{
				type: "text",
				id: "pages.pillar.pillar2.index.blocks.eu-single-market",
				data: {
					text: ["pages.pillar.pillar2.index.blocks.eu-single-market.paragraph1"],
				},
			},
			{
				type: "text-image",
				id: "pages.pillar.pillar2.index.blocks.patents-image",
				data: {
					imageUrl: Sustainability2,
					imageSize: "normal",
					type: "image-right",
					text: [
						"pages.pillar.pillar2.index.blocks.patents-image.paragraph1",
						"pages.pillar.pillar2.index.blocks.patents-image.paragraph2",
						"pages.pillar.pillar2.index.blocks.patents-image.paragraph3",
					],
				},
			},
		],
	},
	"navigation.pillar2.electricalAndElectronicEquipmentPlacedOnTheEuMarket": {
		source: {
			label: "UNU-VIE SCYCLE",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-ElectricalAndElectronicEquipmentPlacedOnTheEUMarket.pdf",
			filename: "APPLiA - Electrical and electronic equipment placed on the EU market",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar2.electricalAndElectronicEquipmentPlacedOnTheEuMarket.blocks.custom",
				data: {
					component: EeePlacedOnTheEuMarket,
					props: {
						data: EeePlacedOnTheEuMarketData,
					},
				},
			},
		],
	},
	"navigation.pillar2.europeanMarketHistoricalPicture": {
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-EuropeanMarketHistoricalPicture.pdf",
			filename: "APPLiA - European market historical picture",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.europeanMarketHistoricalPicture.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.europeanMarketHistoricalPicture.blocks.intro.paragraph"
				}
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.europeanMarketHistoricalPicture.blocks.custom",
				data: {
					component: EuropeanMarketHistoricalPicture,
					props: {
						data: EuropeanMarketHistoricalPictureData,
					},
				},
			},
		],
	},
	"navigation.pillar2.largeAppliancesSoldGlobally": {
		source: {
			label: "IRHMA",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-LargeAppliancesSoldGloballyIn2023.pdf",
			filename: "APPLiA - Large appliances sold globally in 2023",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.largeAppliancesSoldGlobally.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.largeAppliancesSoldGlobally.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.largeAppliancesSoldGlobally.blocks.custom",
				data: {
					component: LargeAppliancesSoldGlobally,
				},
			},
		],
	},
	"navigation.pillar2.unitsTradedInEurope": {
		source: {
			label: "APPLiA members data",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-UnitsTradedInEurope.pdf",
			filename: "APPLiA - Units traded in Europe",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.unitsTradedInEurope.blocks.intro",
				data: {
					text: [
						"pages.pillar.pillar2.unitsTradedInEurope.blocks.intro.paragraph1",
						"pages.pillar.pillar2.unitsTradedInEurope.blocks.section1.paragraph1",
						"pages.pillar.pillar2.unitsTradedInEurope.blocks.section2.paragraph1",
					],
				},
			},

			{
				type: "custom",
				id: "pages.pillar.pillar2.UnitsTradedInEurope.blocks.section1.visual",
				data: { component: UnitsTradedInEurope },
			},
		],
	},
	"navigation.pillar2.importAndExportOfHomeAppliancesInEuropeByContinent": {
		source: {
			label: "Eurostat - International trade in goods",
			url: "https://ec.europa.eu/eurostat/web/international-trade-in-goods/data/database",
		},
		blocks: [
			{
				type: "custom",
				id: "pillar-2-importAndExportOfHomeAppliancesInEuropeByContinent-chart",
				data: {
					component: ImportAndExportOfHomeAppliancesInEuropeByContinent,
					props: {
						data: EUTradeObjects,
					},
				},
			},
		],
	},
	"navigation.pillar2.top10ImportAndExportCountries": {
		source: {
			label: "Eurostat - International trade in goods",
			url: "https://ec.europa.eu/eurostat/web/international-trade-in-goods/data/database",
		},
		blocks: [
			{
				type: "custom",
				id: "pillar-2-top10ImportAndExportCountries",
				data: {
					component: Top10ImportAndExportCountries,
					props: {
						data: CountryTradeObjects,
					},
				},
			},
		],
	},
	"navigation.pillar2.evolutionOfImportAndExportOfHomeAppliancesInEurope": {
		source: {
			label: "Eurostat - International trade in goods",
			url: "https://ec.europa.eu/eurostat/web/international-trade-in-goods/data/database",
		},
		blocks: [
			{
				type: "custom",
				id: "pillar-2-evolutionOfImportAndExportOfHomeAppliancesInEurope-chart",
				data: {
					component: EvolutionOfImportAndExportOfHomeAppliancesInEurope,
					props: {
						data: EUTradeObjects,
					},
				},
			},
		],
	},
	"navigation.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2023": {
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-DirectMembersManufacturingSitesInEuropeIn2023.pdf",
			filename: "APPLiA - APPLiA Direct members manufacturing sites in Europe in 2023",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2023.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2023.blocks.intro.paragraph1",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.appliaDirectMembersManufacturingSitesInEuropeIn2023.blocks.chart",
				data: {
					component: DirectMembersManufacturingSitesInEurope,
					props: {
						data: DirectMembersManufacturingSitesInEuropeData,
					},
				},
			},
		],
	},
	"navigation.pillar2.patentsFiledAndGrantedForHomeAppliances": {
		source: {
			label: "Clarivate Analytics Derwent Innovation",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-PatentsFiledAndGrantedForHomeAppliances.pdf",
			filename: "APPLiA - Patents filed and granted for home appliances",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.patentsFiledAndGrantedForHomeAppliances.blocks.intro",
				data: {
					text: "pages.pillar.pillar2.patentsFiledAndGrantedForHomeAppliances.blocks.intro.paragraph",
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.patentsFiledAndGrantedForHomeAppliances.blocks.chart",
				data: {
					component: PatentsFiledAndGrantedForHomeAppliances,
					props: {
						data: PatentsFiledAndGrantedForHomeAppliancesData,
					},
				},
			},
		],
	},
	"navigation.pillar2.investmentInRAndDByEuMemberStates": {
		source: {
			label: "Eurostat (RD_E_GERDTOT) R&D related to business sector",
			url: "https://ec.europa.eu/eurostat/databrowser/view/RD_E_GERDTOT__custom_4667804/default/table?lang=en",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-InvestmentInRDByEUMemberStates.pdf",
			filename: "APPLiA - Investment in RD by EU member states",
		},
		blocks: [
			{
				type: "text",
				id: "pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.intro",
				data: {
					text: ["pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.intro.paragraph1","pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.intro.paragraph2"],
				},
			},
			{
				type: "custom",
				id: "pages.pillar.pillar2.investmentInRAndDByEuMemberStates.blocks.visual",
				data: {
					component: InvestmentInRAndDByEuMemberStates,
				},
			},
		],
	},
	"navigation.pillar2.investmentRDvsGDP": {
		source: {
			label: "Eurostat (RD_E_GERDTOT) R&D related to business sector",
			url: "https://ec.europa.eu/eurostat/databrowser/view/RD_E_GERDTOT__custom_4667804/default/table?lang=en",
		},
		pdf: {
			path: "/files/pdf/pillar-2/APPLiA-InvestmentInRDByEUMemberStatesAsFractionOfGDP.pdf",
			filename: "APPLiA - Investment in RD by EU member states as fraction of GDP",
		},
		blocks: [
			{
				type: "custom",
				id: "pages.pillar.pillar2.investmentRDvsGDP.blocks.visual",
				data: {
					component: InvestmentRDvsGDP,
					props: {
						data: InvestmentRDvsGDPData,
					},
				},
			},
		],
	},
};
