import { FC } from "react";
import { Helmet } from "react-helmet-async";

import { SeoProps } from "./Seo.type";

const Seo: FC<SeoProps> = ({
	description = "The APPLiA statistical report 2022-2023 is a deep look into sustainability, digitalisation and European competitiveness as fostered by the home appliance sector.",
	name = "APPLiA",
	title,
	type = "article",
}) => {
	return (
		<Helmet>
			<title>{title ? `${title} | ` : ""}APPLiA statistical report 2022-2023</title>
			<meta name="description" content={description} />
			{/* End standard metadata tags */}

			{/* Facebook tags */}
			<meta property="og:type" content={type} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{/* End Facebook tags */}

			{/* Twitter tags */}
			<meta name="twitter:creator" content={name} />
			<meta name="twitter:card" content={type} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
		</Helmet>
	);
};

export { Seo };
