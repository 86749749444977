import { FC } from "react";
import { useTranslation } from "react-i18next";

import Desktop from "../../../../assets/images/content/introduction/roadmap-to-a-fair-sustainable-and-prosperous-european-society/manifesto-pillars-desktop.webp";
import Mobile from "../../../../assets/images/content/introduction/roadmap-to-a-fair-sustainable-and-prosperous-european-society/manifesto-pillars-mobile.webp";

const Roadmap: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col">
			<img
				className="hidden md:block w-full max-w-3xl self-center"
				src={Desktop}
				alt={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
				title={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
			/>
			<img
				className="block md:hidden w-full max-w-sm self-center"
				src={Mobile}
				alt={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
				title={t("pages.introduction.europeHomeApplianceIndustryIn2023.blocks.visual.alt") as string}
			/>
		</div>
	);
};

export { Roadmap };