import { EUTradeObject } from "./../../../Charts/shared/chart.type";

const getValue = (tradeObject: EUTradeObject, key: keyof EUTradeObject) => {
	return tradeObject[key];
};

export const groupAreaData = (rawData: EUTradeObject[]) => {
	const euroData = rawData.filter((tradeObject) => tradeObject.regionName === "EU27");
	const nonEuroData = rawData.filter((tradeObject) => tradeObject.regionName !== "EU27");

	const totalNonEuroData = nonEuroData.reduce((objectList, tradeObject) => {
		const keys = Object.keys(tradeObject).filter((key) => key !== "regionName");
		const newObject = keys.reduce(
			(object: any, key) => {
				object[key] = getValue(tradeObject, key as keyof EUTradeObject);
				return object;
			},
			{ regionName: "NonEuTotal" },
		) as EUTradeObject;
		if (!newObject) {
			return objectList;
		}
		const foundObject = objectList.find(
			(totalTradeObject) =>
				totalTradeObject.flowName === newObject.flowName &&
				totalTradeObject.familyName === newObject.familyName &&
				totalTradeObject.period === newObject.period &&
				totalTradeObject.regionName === newObject.regionName,
		);
		if (!foundObject) {
			objectList.push(newObject);
			return objectList;
		} else {
			const newList = objectList.map((totalTradeObject) => {
				if (totalTradeObject === foundObject) {
					totalTradeObject.valueInEuros = totalTradeObject.valueInEuros + newObject.valueInEuros;
					totalTradeObject.quantityInKg = totalTradeObject.quantityInKg + newObject.quantityInKg;
				}
				return totalTradeObject;
			});
			return newList;
		}
	}, [] as EUTradeObject[]);

	return { euroData, nonEuroData, totalNonEuroData };
};

export const getFilteredData = (dataset: EUTradeObject[], type: string, category: string, unit: string) => {
	const foundButAllUnitsObject = dataset.filter(
		(euroDataObject) => euroDataObject.flowName === type && euroDataObject.familyName === category,
	);
	const selectedUnitObject =
		unit === "valueInEuros"
			? foundButAllUnitsObject.map((euroTradeObject) => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { quantityInKg, valueInEuros, ...restObject } = euroTradeObject;
					return { ...restObject, value: valueInEuros };
			  })
			: foundButAllUnitsObject.map((euroTradeObject) => {
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					const { quantityInKg, valueInEuros, ...restObject } = euroTradeObject;
					return { ...restObject, value: quantityInKg };
			  });
	return selectedUnitObject;
};

export const getUnit = (filterInputType: string) => {
	switch (filterInputType) {
		case "Quantity in kg":
			return "quantityInKg";
		default:
		case "Euros":
			return "valueInEuros";
	}
};
