import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Legend } from "../../../Charts/Legend/Legend";
import { LegendProps } from "../../../Charts/Legend/Legend.type";
import { LineChart } from "../../../Charts/LineChart/LineChart";
import { useBreakpoint } from "../../../../hooks/useBreakpoint";

import { WEEE_FLOW_IN_THE_HOME_APPLIANCE_SECTOR_CHART_DATA_KEYS } from "./WeeeFlowInTheHomeApplianceSector.const";
import { WeeeFlowInTheHomeApplianceSectorLineChartToolTip } from "./tooltip/WeeeFlowInTheHomeApplianceSectorLineChartToolTip";
import { WeeeFlowInTheHomeApplianceSectorProps } from "./WeeeFlowInTheHomeApplianceSector.type";

const WeeeFlowInTheHomeApplianceSector: FC<WeeeFlowInTheHomeApplianceSectorProps> = ({ lineChartData }) => {
	const { t } = useTranslation();
	const chartSize = useBreakpoint();

	const [legendDirection, setLegendDirection] = useState<LegendProps["direction"]>("horizontal");

	const [chartData, setChartData] = useState(lineChartData);

	const [marginLeft, setMarginLeft] = useState(-20);
	const [axisFontSize, setAxisFontSize] = useState(14);
	const [xAxisPadding, setXAxisPadding] = useState(50);
	const [chartHeight, setChartHeight] = useState(350);

	const legendData = useMemo(() => {
		return WEEE_FLOW_IN_THE_HOME_APPLIANCE_SECTOR_CHART_DATA_KEYS.map((dataKey) => {
			const label = typeof dataKey?.translation === "string" ? t(dataKey?.translation) : dataKey.name;

			return {
				color: dataKey.color,
				label: label,
			};
		});
	}, [t]);

	useEffect(() => {
		switch (chartSize) {
			case "xs":
				setChartData(lineChartData.filter((dataEntry) => ["2010", "2015", "2020"].includes(dataEntry.name)));
				setMarginLeft(-27);
				setAxisFontSize(11);
				setXAxisPadding(20);
				setChartHeight(350);
				setLegendDirection("vertical");
				break;

			case "sm":
				setChartData(lineChartData.filter((dataEntry) => ["2008", "2010", "2015", "2017", "2020"].includes(dataEntry.name)));
				setMarginLeft(-27);
				setAxisFontSize(11);
				setXAxisPadding(15);
				setChartHeight(450);
				setLegendDirection("vertical");
				break;

			case "md":
			case "lg":
			case "xl":
				setChartData(lineChartData.filter((_, i) => i % 2 === 0));
				setMarginLeft(-25);
				setAxisFontSize(14);
				setXAxisPadding(20);
				setChartHeight(450);
				setLegendDirection("horizontal");
				break;

			default:
				setChartData(lineChartData);
				setMarginLeft(-25);
				setAxisFontSize(14);
				setXAxisPadding(35);
				setChartHeight(450);
				setLegendDirection("horizontal");
				break;
		}
	}, [chartSize, lineChartData]);

	return (
		<div className="flex flex-col gap-8 mb-4">
			<div className="self-stretch md:self-center">
				<Legend items={legendData} direction={legendDirection} />
			</div>

			<LineChart
				YAxisLabel="Mt"
				data={chartData}
				height={chartHeight}
				marginLeft={marginLeft}
				XAxisProps={{
					dataKey: "name",
					padding: { left: xAxisPadding, right: xAxisPadding },
					tick: { fontSize: axisFontSize },
				}}
				YAxisProps={{
					dataKey: "units",
					tickCount: 11,
					tick: { fontSize: axisFontSize },
					domain: [0, 5],
					interval: 0,
				}}
				dataKeys={WEEE_FLOW_IN_THE_HOME_APPLIANCE_SECTOR_CHART_DATA_KEYS}
				Tooltip={WeeeFlowInTheHomeApplianceSectorLineChartToolTip}
			/>

		</div>
	);
};

export { WeeeFlowInTheHomeApplianceSector };
