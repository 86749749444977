import { LineChartDataKey } from "../../../Charts/LineChart/LineChart.type";
import { UiColors } from "../../../../config/ui/colors";

export const AVERAGE_ENERGY_CONSUMPTION_DISHWASHERS_DATA_KEYS: Array<LineChartDataKey> = [
	{
		name: "average",
		translation: "pages.pillar.pillar1.averageEnergyConsumption.blocks.chart-dishwashers.dataKeys.average",
		color: UiColors.charts.secondary,
		showLabel: true,
	},
];