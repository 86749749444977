import AlvaroVilas from "../../../../images/content/introduction/applia-secretariat/AlvaroVilas.webp";
import AntonArabadjev from "../../../../images/content/introduction/applia-secretariat/AntonArabadjev.webp";
import CandiceFranck from "../../../../images/content/introduction/applia-secretariat/CandiceFranck.webp";
import CandiceRichaud from "../../../../images/content/introduction/applia-secretariat/CandiceRichaud.webp";
import FedericaLavoro from "../../../../images/content/introduction/applia-secretariat/FedericaLavoro.webp";
import FranziskaDecker from "../../../../images/content/introduction/applia-secretariat/FranziskaDecker.webp";
import GiuliaZilla from "../../../../images/content/introduction/applia-secretariat/GiuliaZilla.webp";
import MariaVittoriaSbrescia from "../../../../images/content/introduction/applia-secretariat/MariaVittoriaSbrescia.webp";
import KorrinaHegarty from "../../../../images/content/introduction/applia-secretariat/KorrinaHegarty.webp";
import MichalZakrzewski from "../../../../images/content/introduction/applia-secretariat/MichalZakrzewski.webp";
import MartinOresic from "../../../../images/content/introduction/applia-secretariat/MartinOresic.webp";
import MatteoRambaldi from "../../../../images/content/introduction/applia-secretariat/MatteoRambaldi.webp";
import NaomiMarc from "../../../../images/content/introduction/applia-secretariat/NaomiMarc.webp";
import PaoloFalcioni from "../../../../images/content/introduction/applia-secretariat/PaoloFalcioni.webp";

export const AppliaSecretariatMembers = [
	{
		name: "Paolo Falcioni",
		imageUrl: PaoloFalcioni,
		background: "pages.introduction.appliaSecretariat.members.paoloFalcioni.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.paoloFalcioni.functionTitle",
	},
	{
		name: "Candice Franck",
		imageUrl: CandiceFranck,
		background: "pages.introduction.appliaSecretariat.members.candiceFranck.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.candiceFranck.functionTitle",
	},
	{
		name: "Federica Lavoro",
		imageUrl: FedericaLavoro,
		background: "pages.introduction.appliaSecretariat.members.federicaLavoro.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.federicaLavoro.functionTitle",
	},
	{
		name: "Maria Vittoria Sbrescia",
		imageUrl: MariaVittoriaSbrescia,
		background: "pages.introduction.appliaSecretariat.members.mariaVittoriaSbrescia.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.mariaVittoriaSbrescia.functionTitle",
	},
	{
		name: "Korrina Hegarty",
		imageUrl: KorrinaHegarty,
		background: "pages.introduction.appliaSecretariat.members.korrinaHegarty.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.korrinaHegarty.functionTitle",
	},
	{
		name: "Naomi Marc",
		imageUrl: NaomiMarc,
		background: "pages.introduction.appliaSecretariat.members.naomiMarc.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.naomiMarc.functionTitle",
	},
	{
		name: "Franziska Decker",
		imageUrl: FranziskaDecker,
		background: "pages.introduction.appliaSecretariat.members.franziskaDecker.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.franziskaDecker.functionTitle",
	},
	{
		name: "Matteo Rambaldi",
		imageUrl: MatteoRambaldi,
		background: "pages.introduction.appliaSecretariat.members.matteoRambaldi.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.matteoRambaldi.functionTitle",
	},
	{
		name: "Giulia Zilla",
		imageUrl: GiuliaZilla,
		background: "pages.introduction.appliaSecretariat.members.giuliaZilla.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.giuliaZilla.functionTitle",
	},
	{
		name: "Anton Arabadjev",
		imageUrl: AntonArabadjev,
		background: "pages.introduction.appliaSecretariat.members.antonArabadjev.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.antonArabadjev.functionTitle",
	},
	{
		name: "Candice Richaud",
		imageUrl: CandiceRichaud,
		background: "pages.introduction.appliaSecretariat.members.candiceRichaud.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.candiceRichaud.functionTitle",
	},
	{
		name: "Martin Oresic",
		imageUrl: MartinOresic,
		background: "pages.introduction.appliaSecretariat.members.martinOresic.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.martinOresic.functionTitle",
	},
	{
		name: "Michał Zakrzewski",
		imageUrl: MichalZakrzewski,
		background: "pages.introduction.appliaSecretariat.members.michalZakrzewski.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.michalZakrzewski.functionTitle",
	},
	{
		name: "Alvaro Vilas",
		imageUrl: AlvaroVilas,
		background: "pages.introduction.appliaSecretariat.members.alvaroVilas.background",
		functionTitle: "pages.introduction.appliaSecretariat.members.alvaroVilas.functionTitle",
	},
];
