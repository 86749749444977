import { TFunction } from "i18next";

import { AppliaChildRoute, AppliaRoute } from "../../../config/routes/routes";

export const parseRouteTitle = (
	parentNode: AppliaRoute | undefined,
	currentRouteInfo: AppliaRoute | AppliaChildRoute | undefined,
	t: TFunction,
) => {
	let title = "";

	if (typeof t !== "function") {
		return title;
	}

	if (
		parentNode &&
		(parentNode.url !== currentRouteInfo?.url || parentNode?.pathName !== currentRouteInfo?.pathName) &&
		parentNode?.label !== currentRouteInfo?.label
	) {
		if (parentNode?.pillarNumber) {
			title = `${t(currentRouteInfo?.label as string) || ""} - Pillar ${parentNode?.pillarNumber || ""}: ${
				t(parentNode?.label as string) || ""
			}`;
		} else {
			title = `${t(currentRouteInfo?.label as string) || ""} - ${t(parentNode?.label) || ""}`;
		}
	} else {
		if ((currentRouteInfo as AppliaRoute)?.pillarNumber) {
			title = `Pillar ${(currentRouteInfo as AppliaRoute)?.pillarNumber || ""}: ${t(currentRouteInfo?.label as string) || ""}`;
		} else {
			title = `${t(currentRouteInfo?.label as string) || ""}`;
		}
	}

	return title;
};
