import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, XAxisProps, LabelList } from "recharts";
import { FC, useEffect, useMemo, useState } from "react";

import { ChartSize } from "../../../types/charts/size";
import { CustomizedYAxisLabel } from "../shared/CustomizedYAxisLabel/CustomizedYAxisLabel";
import { CustomizedYAxisTick } from "../shared/CustomizedYAxisTick/CustomizedYAxisTick";
import { getTickFontSize, valueAccessor } from "../shared/HorizontalBarChart.helper";
import { UiColors } from "../../../config/ui/colors";
import { useTextMeasurer } from "../../../hooks/charts/useTextMeasurer";
import { useWindowSize } from "../../../hooks/useWindowSize";

import { HorizontalBarChartProps } from "./HorizontalBarChart.type";

const HorizontalBarChart: FC<HorizontalBarChartProps> = ({
	data,
	dataKeys,
	height,
	barLabelFormatter,
	YAxisProps,
	XAxisProps,
	barLabelPosition = "inside",
	barLabelColor = UiColors.white,
}) => {
	const { measureText } = useTextMeasurer(getTickFontSize(YAxisProps));
	const { width } = useWindowSize();

	const [chartSize, setChartSize] = useState<ChartSize>("md");
	const [chartHeight, setChartHeight] = useState<number>(data.length * 47 + 30);
	const [chartYAxisLabelPosition, setChartYAxisLabelPosition] = useState<"inline" | "outside">("outside");
	const [leftMargin, setLeftMargin] = useState<number>(-60);

	useEffect(() => {
		if (width < 475) {
			setChartSize("xs");
		} else if (width < 576) {
			setChartSize("sm");
		} else if (width < 960) {
			setChartSize("md");
		} else {
			setChartSize("lg");
		}
	}, [width]);

	useEffect(() => {
		const measuredLabelWidths = data.map((value: any) => measureText(value?.name));

		switch (chartSize) {
			case "xs":
				setLeftMargin(-60);
				setChartYAxisLabelPosition("inline");
				setChartHeight(data.length * 75 + 30);
				break;

			case "sm":
				setLeftMargin(-60);
				setChartYAxisLabelPosition("inline");
				setChartHeight(data.length * 75 + 30);
				break;

			case "md":
				setLeftMargin(-60);
				setChartYAxisLabelPosition("inline");
				setChartHeight(data.length * 70 + 30);
				break;

			default:
				setLeftMargin(Math.max(...measuredLabelWidths));
				setChartHeight(data.length * 47 + 30);
				setChartYAxisLabelPosition("outside");
				break;
		}
	}, [chartSize, data, measureText]);

	const XAxisCombinedProps = useMemo<XAxisProps>(() => {
		return {
			...XAxisProps,
			axisLine: { stroke: UiColors.charts.grid, ...(typeof XAxisProps?.axisLine === "object" && { ...XAxisProps.axisLine }) },
			type: "number",
			tick: { fill: UiColors.black, ...(XAxisProps?.tick as object) },
			tickLine: false,
			interval: 0,
			tickMargin: 7.5,
		};
	}, [XAxisProps]);

	return (
		<div className="w-full">
			<ResponsiveContainer width="99%" height={height || chartHeight}>
				<BarChart barSize={22} data={data} layout="vertical" margin={{ top: 20, right: 0, bottom: 0, left: leftMargin }}>
					<CartesianGrid stroke={UiColors.charts.grid} horizontal={false} />

					<XAxis {...XAxisCombinedProps} />
					<YAxis
						{...YAxisProps}
						type="category"
						tickMargin={10}
						axisLine={{ stroke: UiColors.charts.grid, ...(typeof YAxisProps?.axisLine === "object" && { ...YAxisProps.axisLine }) }}
						{...(chartYAxisLabelPosition === "outside" && {
							tick: (props) => <CustomizedYAxisTick {...props} {...(typeof YAxisProps?.tick === "object" && { ...YAxisProps.tick })} />,
						})}
						{...(chartYAxisLabelPosition === "inline" && {
							tick: false,
						})}
						tickLine={false}
						interval={0}
					/>

					{dataKeys.map((dataKey, index) => (
						<Bar key={dataKey.name} dataKey={dataKey.name} stackId="a" fill={dataKey.color} isAnimationActive={false}>
							{["xs", "sm", "md"].includes(chartSize) && index === 0 && (
								<LabelList
									transform="translate(7.5 -7.5)"
									fill={UiColors.black}
									fontSize={14}
									valueAccessor={valueAccessor("name")}
									position="insideLeft"
									fontWeight="bold"
									content={(props) => <CustomizedYAxisLabel {...props} position="insideLeft" />}
								/>
							)}
							<LabelList
								formatter={typeof barLabelFormatter === "function" ? (props: any) => barLabelFormatter(props) : undefined}
								fill={barLabelColor}
								position={barLabelPosition}
								fontSize={12}
								valueAccessor={valueAccessor(dataKey.name)}
							/>
						</Bar>
					))}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export { HorizontalBarChart };
