import { AppliaRoute } from "../../../config/routes/routes";

export const parseRouteLabel = (route: AppliaRoute, translation?: string) => {
	if (typeof route?.label !== "string") {
		return "";
	}

	if (typeof route?.pillarNumber === "number") {
		return `<span class="text-lg font-semibold">Pillar ${route.pillarNumber}:</span> <br/> ${translation || route.label}`;
	}

	if (typeof translation === "string") {
		return translation;
	}

	return route.label;
};
