import { FC } from "react";

import Desktop from "../../../../assets/vectors/content/pillar-1/plastic-flows-from-home-appliances/plastic-flows-desktop.svg";
import Mobile from "../../../../assets/vectors/content/pillar-1/plastic-flows-from-home-appliances/plastic-flow-mobile.svg";

const PlasticFlowsFromHomeAppliances: FC = () => {
	return (
		<div className="flex flex-col items-center justify-center">
			<img className="hidden md:block w-full" src={Desktop} role="presentation" />
			<img className="block md:hidden" src={Mobile} role="presentation" />
		</div>
	);
};

export { PlasticFlowsFromHomeAppliances };
