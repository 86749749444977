import { AppliaPillarNarrative } from "../../config/routes/routes";

export const narrativeTranslationKeys: { [key in AppliaPillarNarrative]: string } = {
	"Market trends": "pages.pillar.narratives.market-trends",
	"Material flow": "pages.pillar.narratives.material-flow",
	Production: "pages.pillar.narratives.production",
	"R&D": "pages.pillar.narratives.r-and-d",
	Recycling: "pages.pillar.narratives.recycling",
	Repair: "pages.pillar.narratives.repair",
	"Secondary raw materials": "pages.pillar.narratives.secondary-raw-materials",
	"Use & consumption": "pages.pillar.narratives.use-and-consumption",
};
