import { useEffect, useState } from "react";

import { debounce } from "../utils/debounce/debounce";

const getSize = () => {
	return {
		width: window.innerWidth,
		height: window.innerHeight,
	};
};

export function useWindowSize(debounceTime = 250) {
	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		// Debounce to avoid the function fire multiple times
		const handleResizeDebounced = debounce(function handleResize() {
			setWindowSize(getSize());
		}, debounceTime);

		window.addEventListener("resize", handleResizeDebounced);
		return () => window.removeEventListener("resize", handleResizeDebounced);
	}, [debounceTime]);

	return windowSize;
}
